import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import { Modal } from '@ui/Modal';
import React from 'react';
import { whatsappSupportNumber } from '../../../../../../../config/env.json';

import './modal-redirect-whatsapp.css';

type ModalRedirectWhatsappProps = {
  readonly isOpen: boolean;
  readonly infoText: string;
  readonly close: () => void;
};

function ModalRedirectWhatsapp({
  isOpen,
  infoText,
  close
}: ModalRedirectWhatsappProps) {
  const number = String(whatsappSupportNumber);
  const whatsappURL = `https://api.whatsapp.com/send/?phone=${number}&text=${infoText}&type=phone_number&app_absent=0`;

  return (
    <Modal.Root
      show={isOpen}
      onHide={close}
      className='modal-redirect-whatsapp'
    >
      <Modal.Header onHide={close}>AVISO DE REDIRECIONAMENTO</Modal.Header>
      <Modal.Body>
        <p>
          Você será direcionado para o nosso whatsapp, onde poderá informar
          erros na plataforma.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          buttonType={ButtonTypes.Tertiary}
          width='calc(50% - 8px)'
          onClick={close}
        >
          SAIR
        </Button>
        <Button
          buttonType={ButtonTypes.Primary}
          width='calc(50% - 8px)'
          href={whatsappURL}
        >
          CONTINUAR
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

export default ModalRedirectWhatsapp;
