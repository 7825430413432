import React, { CSSProperties } from 'react';

import PrismFormatted from './prism-formatted';
import './challenge-description.css';

type Challenge = {
  readonly block?: string;
  readonly description?: string;
  readonly instructions?: string;
  readonly superBlock?: string;
  readonly style?: CSSProperties;
};

function ChallengeDescription(challenge: Challenge): JSX.Element {
  const sbClass = challenge.superBlock ? challenge.superBlock : '';
  const bClass = challenge.block ? challenge.block : '';

  return (
    <div
      className={`challenge-instructions ${sbClass} ${bClass}`}
      style={challenge.style}
    >
      {challenge.description && <PrismFormatted text={challenge.description} />}
      {challenge.instructions && (
        <PrismFormatted text={challenge.instructions} />
      )}
    </div>
  );
}

ChallengeDescription.displayName = 'ChallengeDescription';

export default ChallengeDescription;
