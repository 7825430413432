import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { challengeTypes } from '../../../../utils/challenge-types';

import { executeChallenge, openModal } from '../redux/actions';
import {
  challengeMetaSelector,
  isNotAllowedChallengeModalOpenSelector
} from '../redux/selectors';

import { saveChallenge } from '../../../redux/actions';
import { isSignedInSelector } from '../../../redux/selectors';

import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';
import { checkIsCertificateCheckpoint } from '../../../../../utils/nacional-utils';
import { Challenge, User } from '../../../redux/prop-types';
import { AccessLevel } from '../../../utils/enums/access-levels';

import './tool-panel.css';

const mapStateToProps = createSelector(
  challengeMetaSelector,
  isSignedInSelector,
  isNotAllowedChallengeModalOpenSelector,
  (
    { challengeType }: { challengeId: string; challengeType: number },
    isSignedIn: boolean,
    isNotAllowedChallenge: boolean
  ) => ({
    challengeType,
    isSignedIn,
    isNotAllowedChallenge
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      executeChallenge,
      openResetModal: () => openModal('reset'),
      openHelpModal: () => openModal('help'),
      saveChallenge
    },
    dispatch
  );

interface ToolPanelProps {
  readonly challengeType: number;
  readonly isSignedIn: boolean;
  readonly isLastChallenge: boolean;
  readonly executeChallenge: (options?: {
    showCompletionModal: boolean;
    isLastChallenge: boolean;
    isNacionalCertificate: boolean;
  }) => void;
  readonly saveChallenge: () => void;
  readonly openResetModal: () => void;
  readonly challenge: Challenge;
  readonly user: User;
  readonly isNotAllowedChallenge: boolean;
}

function ToolPanel({
  challengeType,
  executeChallenge,
  saveChallenge,
  isSignedIn,
  openResetModal,
  isLastChallenge,
  challenge,
  user,
  isNotAllowedChallenge
}: ToolPanelProps) {
  const isCheckpointCertificate: boolean =
    checkIsCertificateCheckpoint(challenge);

  const handleRunTests = () => {
    executeChallenge({
      showCompletionModal: true,
      isLastChallenge,
      isNacionalCertificate:
        isCheckpointCertificate &&
        (user.access === AccessLevel.CompleteNacional ||
          user.access === AccessLevel.LimitedNacional)
    });
  };
  const { t } = useTranslation();

  return (
    <div className='tool-panel-container'>
      <div className='tool-panel-group'>
        <Button
          id='run-code-button'
          buttonType={ButtonTypes.Quaternary}
          onClick={handleRunTests}
          disabled={isNotAllowedChallenge}
          style={{ flex: 1 }}
        >
          EXECUTAR
        </Button>

        {isSignedIn &&
          challengeType === challengeTypes.multifileCertProject && (
            <button
              className='btn-secondary-round'
              data-cy='save-code-to-database-btn'
              onClick={saveChallenge}
            >
              {t('buttons.save-code')}
            </button>
          )}

        {challengeType !== challengeTypes.multifileCertProject && (
          <Button
            id='restart-code-button'
            buttonType={ButtonTypes.Light}
            onClick={openResetModal}
            style={{ flex: 1 }}
          >
            LIMPAR
          </Button>
        )}
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ToolPanel);
