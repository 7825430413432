import React from 'react';

function Close2(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
      {...props}
    >
      <mask
        id='mask0_3776_935'
        style={{ maskType: 'alpha' }}
        width='18'
        height='18'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#D9D9D9' d='M0 0H18V18H0z'></path>
      </mask>
      <g mask='url(#mask0_3776_935)'>
        <path
          fill={props.fill ?? '#484848'}
          d='M4.8 13.99l-.79-.79L8.21 9l-4.2-4.2.79-.79L9 8.21l4.2-4.2.79.79L9.79 9l4.2 4.2-.79.79L9 9.79l-4.2 4.2z'
        ></path>
      </g>
    </svg>
  );
}

Close2.displayName = 'Close2';

export default Close2;
