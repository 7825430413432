import React from 'react';
import type { Dispatch } from 'redux';
import { styled } from '@mui/material';
import InputPassword from '@ui/input-password';
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormReset
} from 'react-hook-form';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { changePassword } from '@redux/actions';

const Container = styled('div')`
  div.section-header {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  span {
    font-style: italic;
    font-size: 0.8rem;
  }

  form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  form div.form-elements {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

type ChangePasswordProps = {
  changePassword: (data: {
    currentPassword: string;
    newPassword: string;
    reset: UseFormReset<FormDataProps>;
  }) => void;
};

type FormDataProps = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const formValues: FormDataProps = {
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      changePassword
    },
    dispatch
  );

function ChangePassword({ changePassword }: ChangePasswordProps) {
  const {
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    control
  } = useForm<FormDataProps>({
    defaultValues: formValues
  });

  const onSubmit: SubmitHandler<FormDataProps> = (data: FormDataProps) => {
    changePassword({
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      reset: () => reset(formValues)
    });
  };

  const validateNewPassword = (value: string) => {
    if (value === watch('currentPassword')) {
      return 'A nova senha deve ser diferente da senha atual';
    }
    return true;
  };

  const validateConfirmPassword = (value: string) => {
    if (value !== watch('newPassword')) {
      return 'A nova senha e confirmar senha devem ser iguais';
    }
    return true;
  };

  return (
    <Container>
      <form
        id='change-password-settings'
        onSubmit={handleSubmit(onSubmit) as () => void}
      >
        <div className='section-header'>
          <h4 className='section-header-text' id='change-passoword-settings'>
            Alterar senha
          </h4>
        </div>

        <div className='form-elements'>
          <Controller
            control={control}
            name='currentPassword'
            rules={{
              required: 'O campo de senha atual é obrigatório',
              minLength: {
                value: 8,
                message: 'A senha deve ter no minimo 8 caracteres'
              }
            }}
            render={({ field }) => (
              <InputPassword
                label='Senha atual'
                hasError={!!errors.currentPassword?.message}
                messageError={errors.currentPassword?.message}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name='newPassword'
            rules={{
              required: 'O campo de nova senha é obrigatório',
              minLength: {
                value: 8,
                message: 'A senha deve ter no minimo 8 caracteres'
              },
              validate: validateNewPassword
            }}
            render={({ field }) => (
              <InputPassword
                label='Nova senha'
                hasError={!!errors.newPassword?.message}
                messageError={errors.newPassword?.message}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name='confirmPassword'
            rules={{
              required: 'O campo de confirmar senha é obrigatório',
              minLength: {
                value: 8,
                message: 'A senha deve ter no minimo 8 caracteres'
              },
              validate: validateConfirmPassword
            }}
            render={({ field }) => (
              <InputPassword
                label='Confirmar senha'
                hasError={!!errors.confirmPassword?.message}
                messageError={errors.confirmPassword?.message}
                {...field}
              />
            )}
          />
        </div>

        <Button
          buttonType={ButtonTypes.Primary}
          type='submit'
          width='100%'
          style={{ margin: '0 auto' }}
          disabled={Object.keys(errors).length > 0}
        >
          Salvar
        </Button>
      </form>
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
