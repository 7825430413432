import React from 'react';

interface VideoPlayerProps {
  readonly videoId: string;
  readonly videoStyle?: React.CSSProperties;
}

function VideoPlayer({ videoId, videoStyle }: VideoPlayerProps): JSX.Element {
  return (
    <iframe
      title={videoId}
      style={{ border: 0, ...videoStyle }}
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen'
      src={`https://www.youtube.com/embed/${videoId}?modestbranding=1&enablejsapi=1&controls=1&disablekb=0&rel=0`}
    />
  );
}

export default VideoPlayer;
