import React, { useRef, useEffect, useState } from 'react';
import './custom-reflex-splitter.css';
import { ReflexSplitter, ReflexSplitterProps } from 'react-reflex';
import imgIcon from '../../../assets/images/expand-section.png';

const CustomReflexSplitter = (props: ReflexSplitterProps) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const updateCursor = (event: MouseEvent) => {
      const safeZone = 50;
      const { clientX, clientY } = event;
      const { innerWidth: windowWidth, innerHeight: windowHeight } = window;
      const x = Math.min(Math.max(clientX, safeZone), windowWidth - safeZone);
      const y = Math.min(Math.max(clientY, safeZone), windowHeight - safeZone);
      const componentRect = componentRef.current?.getBoundingClientRect();
      if (componentRect) {
        setPosition({
          x: x - componentRect.left,
          y: y - componentRect.top
        });
      }
    };

    window.addEventListener('mousemove', updateCursor);
    return () => window.removeEventListener('mousemove', updateCursor);
  }, []);

  return (
    <ReflexSplitter
      className='custom-splitter separated-splitter'
      style={{ cursor: 'none' }}
      {...props}
    >
      <div
        ref={componentRef}
        className='wrapper-area'
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        role='none'
      >
        <div
          className='custom-cursor'
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`,
            backgroundImage: isHovered ? `url(${imgIcon})` : 'none'
          }}
        />
      </div>
    </ReflexSplitter>
  );
};

export default CustomReflexSplitter;
