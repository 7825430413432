import { Alert } from '@devstart/react-bootstrap';
import { createSelector } from 'reselect';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import Close3 from '@assets/icons/close3';
import { ChallengeFile } from '@redux/prop-types';
import { connect } from 'react-redux';
import Download from '@assets/icons/download';
import { currentChallengeSelector } from '../../templates/Challenges/redux/selectors';
import { FlashState } from '../../redux/types';
import { dasherize } from '../../../../utils/slugs';
import { removeFlashMessage } from './redux';

import './flash.css';

type Challenge = {
  title: string;
  phase: string;
  superBlock: string;
};
type FlashProps = {
  readonly flashMessage: FlashState['message'];
  readonly removeFlashMessage: typeof removeFlashMessage;
  readonly challenge: Challenge;
};

type ChallengeFileWithPath = ChallengeFile & {
  path?: string;
};

const mapStateToProps = createSelector(
  currentChallengeSelector,
  (challenge: Challenge) => ({
    challenge
  })
);

function Flash({
  flashMessage,
  removeFlashMessage,
  challenge
}: FlashProps): JSX.Element {
  const { type, message, id, variables = {}, challengeFiles } = flashMessage;
  const { title = 'file' } = challenge || {};
  const { t } = useTranslation();
  function handleClose() {
    removeFlashMessage();
  }

  if (variables?.dismissible) {
    setTimeout(() => {
      handleClose();
    }, 1000 * 10); // 10 seconds
  }

  function handleDownloadSolution() {
    if (challengeFiles && challengeFiles.length > 0) {
      const filesForDownload = challengeFiles.reduce<string>(
        (allFiles, currentFile: ChallengeFileWithPath) => {
          console.log('handleDownloadSolution currentFile', currentFile);
          const beforeText = `** start of ${currentFile?.path ?? ''} **\n\n`;
          const afterText = `\n\n** end of ${currentFile?.path ?? ''} **\n\n`;
          allFiles +=
            challengeFiles.length > 1
              ? `${beforeText}${currentFile.contents}${afterText}`
              : currentFile.contents;
          return allFiles;
        },
        ''
      );

      const blob = new Blob([filesForDownload], { type: 'text/plain' });
      const downloadUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.download = `${dasherize(title)}.txt`;
      link.href = downloadUrl;
      link.click();

      URL.revokeObjectURL(downloadUrl);
    }
  }

  return (
    <TransitionGroup>
      <CSSTransition classNames='flash-message' key={id} timeout={500}>
        <Alert
          bsStyle={type}
          className='flash-message'
          closeLabel={t('buttons.close')}
          onDismiss={handleClose}
        >
          <div className='flash-message-content'>
            {t(message, variables)}

            {challengeFiles && challengeFiles?.length > 0 && (
              <Button
                buttonType={ButtonTypes.Senary}
                onClick={handleDownloadSolution}
                insideIcon={<Download fill='#FFFFFF' />}
              >
                {t('learn.download-solution')}
              </Button>
            )}
          </div>

          <div className='close-flash-solution'>
            <Close3
              fill='#FFFFFF'
              className='close-icon'
              onClick={handleClose}
            />
          </div>
        </Alert>
      </CSSTransition>
    </TransitionGroup>
  );
}

Flash.displayName = 'FlashMessages';
export default connect(mapStateToProps)(Flash);
