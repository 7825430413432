import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import { Grid, Col } from '@devstart/react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { navigate } from 'gatsby-link';
import { Helmet } from 'react-helmet';
import { ObserveKeys } from 'react-hotkeys';

import LearnLayout from '../../../components/layouts/learn';
import BreadCrumb from '../components/bread-crumb';

import {
  completedChallengesIdsSelector,
  isChallengeCompletedSelector,
  isCompletedChallengesLoading
} from '../redux/selectors';
import {
  isSignedInSelector,
  userRegistrationStatusSelector,
  userSelector
} from '../../../redux/selectors';
import {
  challengeMounted,
  isAllowedChallenge,
  openModal,
  submitChallenge,
  updateChallengeMeta,
  updateQuizAnswers
} from '../redux/actions';
import {
  createFlashMessage,
  removeFlashMessage
} from '../../../components/Flash/redux';
import { changeTourStatus } from '../../../redux/actions';
import {
  AllChallengeNode,
  ChallengeMeta,
  ChallengeNode,
  QuizAnswers,
  User
} from '../../../redux/prop-types';
import ModalCompleteTrail0Cert from '../../../components/modals/modal-complete-trail-0-cert';
import CompletionModal from '../components/completion-modal';
import ChallengeTitle from '../components/challenge-title';
import PrismFormatted from '../components/prism-formatted';
import { ButtonTypes } from '../../../components/ui/Button/button-types';
import Button from '../../../components/ui/Button';
import AlertQuizModal from '../components/alert-quiz-modal';
import { Spacer } from '../../../components/helpers';

const mapStateToProps = createStructuredSelector({
  isChallengeCompleted: isChallengeCompletedSelector,
  isSignedIn: isSignedInSelector,
  completedChallengesIds: completedChallengesIdsSelector,
  user: userSelector,
  userRegistrationStatus: userRegistrationStatusSelector,
  isCompletedChallengesLoading
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateChallengeMeta,
      createFlashMessage,
      removeFlashMessage,
      challengeMounted,
      openCompletionQuizModal: () => openModal('completionQuiz'),
      submitChallenge,
      navigate,
      isAllowedChallenge,
      changeTourStatus,
      updateQuizAnswers
    },
    dispatch
  );

interface ShowQuizProps {
  readonly challengeMounted: (arg0: string) => void;
  readonly data: {
    challengeNode: ChallengeNode;
    allChallengeNode: AllChallengeNode;
  };
  readonly user: User;
  readonly openCompletionQuizModal: () => void;
  readonly submitChallenge: () => void;
  readonly pageContext: {
    challengeMeta: ChallengeMeta;
  };
  readonly updateChallengeMeta: (arg0: ChallengeMeta) => void;
  readonly updateQuizAnswers: ({
    userAnswers,
    realAnswers
  }: QuizAnswers) => void;
}

function ShowQuiz({
  data: {
    challengeNode: {
      challenge,
      challenge: {
        fields: { blockName },
        title,
        challengeType,
        helpCategory,
        superBlock,
        certification,
        block,
        phase,
        quiz
      }
    }
  },
  pageContext: { challengeMeta },
  updateChallengeMeta,
  challengeMounted,
  openCompletionQuizModal,
  submitChallenge,
  updateQuizAnswers,
  user
}: ShowQuizProps) {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: number]: number;
  }>({});
  const [alertModal, setAlertModal] = useState(false);

  useEffect(() => {
    updateChallengeMeta({
      ...challengeMeta,
      title,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
    if (
      user.isFundamentosDaProgramacaoFrontEndCert === true ||
      user.attemptFPFCert === 2
    ) {
      openCompletionQuizModal();
    }
  }, [
    challengeMeta,
    challengeMounted,
    challengeType,
    helpCategory,
    openCompletionQuizModal,
    title,
    updateChallengeMeta,
    user.attemptFPFCert,
    user.isFundamentosDaProgramacaoFrontEndCert
  ]);

  const blockNameTitle = `${t(
    `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
  )} - ${title}`;

  const handleOptionChange = (
    questionIndex: number,
    optionIndex: number
  ): void => {
    setSelectedOptions(prevState => ({
      ...prevState,
      [questionIndex]: optionIndex
    }));
  };

  const prepareAnswers = () => {
    const userAnswers: number[] = Object.values(selectedOptions);
    const realAnswers = quiz.reduce((accumulator, question) => {
      accumulator.push(question.solution);
      return accumulator;
    }, [] as number[]);

    return {
      userAnswers,
      realAnswers
    };
  };

  const handleSubmit = (
    openCompletionQuizModal: () => void,
    submitChallenge: () => void
  ) => {
    submitChallenge();
    openCompletionQuizModal();
  };

  const breadCrumbItems = [
    {
      key: 'superblock-title',
      value: t(`intro:${superBlock}.title`),
      redirectsTo: `/learn/${superBlock}`
    },
    {
      key: 'superblock-phase',
      value: t(`intro:${superBlock}.phases.${phase}.title`),
      redirectsTo: `/learn/${superBlock}/#${block}`
    },
    {
      key: 'superblock-theme',
      value: t(`intro:${superBlock}.phases.${phase}.blocks.${block}.title`),
      redirectsTo: `/learn/${superBlock}/#${block}`
    },
    {
      key: 'superblock-challenge',
      value: title,
      redirectsTo: ''
    }
  ];

  return (
    <LearnLayout challenge={challenge}>
      <Helmet title={`${blockNameTitle} | ${t('learn.learn')} | DEVStart`} />
      <Grid className='navigation-container-video'>
        <Grid className='navigation-container-video'>
          <BreadCrumb breadCrumbItems={breadCrumbItems} />
        </Grid>
      </Grid>
      <Grid className='container-video'>
        <ChallengeTitle>{title}</ChallengeTitle>
        <Spacer size='large' />
        <Col sm={12} style={{ padding: 0 }}>
          <ObserveKeys>
            <div className='video-quiz-options'>
              {quiz.map((question, questionIndex) => (
                <React.Fragment key={question.title}>
                  <PrismFormatted
                    className='line-questions'
                    text={question.title}
                  />
                  {question.alternatives.map((alternative, optionIndex) => {
                    const isSelected =
                      selectedOptions[questionIndex] === optionIndex + 1;
                    return (
                      <label
                        className='video-quiz-option-label'
                        key={question.title}
                      >
                        <input
                          className='video-quiz-input-hidden'
                          name={`quiz-${questionIndex}`}
                          type='radio'
                          checked={isSelected}
                          onChange={() =>
                            handleOptionChange(questionIndex, optionIndex + 1)
                          }
                          value={optionIndex + 1}
                        />
                        <span className='video-quiz-input-visible'>
                          {isSelected ? (
                            <span className='video-quiz-selected-input' />
                          ) : null}
                        </span>
                        <PrismFormatted
                          className='video-quiz-option'
                          text={alternative}
                        />
                      </label>
                    );
                  })}
                  <hr />
                </React.Fragment>
              ))}
            </div>
          </ObserveKeys>
        </Col>
        {challengeMeta.nextChallengePath ? (
          <Button
            buttonType={ButtonTypes.Quaternary}
            id='big-button'
            disabled={Object.values(selectedOptions).length !== 10}
            onClick={() => {
              setAlertModal(true);
            }}
          >
            {t('buttons.go-to-next')}
          </Button>
        ) : null}
        <CompletionModal
          block={block}
          blockName={blockName}
          certification={certification}
          superBlock={superBlock}
        />
        <ModalCompleteTrail0Cert
          answers={prepareAnswers()}
          userAttemptFPFCert={user.attemptFPFCert === null ? 1 : 2}
        />
        <AlertQuizModal
          attempt={user.attemptFPFCert}
          close={() => setAlertModal(false)}
          isOpen={alertModal}
          buttonAction={() => {
            setAlertModal(false);
            updateQuizAnswers(prepareAnswers());
            handleSubmit(openCompletionQuizModal, submitChallenge);
          }}
        />
      </Grid>
    </LearnLayout>
  );
}

ShowQuiz.displayName = 'ShowQuiz';

export default connect(mapStateToProps, mapDispatchToProps)(ShowQuiz);

export const query = graphql`
  query QuizChallenge($slug: String!) {
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        title
        description
        phase
        challengeType
        helpCategory
        superBlock
        quiz {
          alternatives
          title
          solution
        }
        certification
        superOrder
        phaseOrder
        order
        challengeOrder
        block
        fields {
          blockName
          slug
        }
        translationPending
      }
    }
  }
`;
