import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Grid, Row } from '@devstart/react-bootstrap';
import { getUserHasPassword } from '@utils/ajax';
import {
  createFlashMessage,
  removeFlashMessage
} from '@components/Flash/redux';
import { FlashMessages } from '@components/Flash/redux/flash-messages';
import { unlockUserAccount } from '../redux/actions';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { ButtonTypes } from '../components/ui/Button/button-types';
import { Spacer } from '../components/helpers';
import BackButton from '../components/ui/BackButton';
import InputPassword from '../components/ui/input-password';

import './forgot-password.css';

type FormValues = {
  cpf: string;
  password: string;
  email: string;
};

type UnlockAccountProps = {
  readonly unlockUserAccount: (formValues: FormValues) => void;
  readonly createFlashMessage: typeof createFlashMessage;
  readonly removeFlashMessage: typeof removeFlashMessage;
};

const mapDispatchToProps = {
  unlockUserAccount,
  createFlashMessage,
  removeFlashMessage
};

const defaultValues = {
  cpf: '',
  password: '',
  email: ''
};

function UnlockAccount({
  unlockUserAccount,
  createFlashMessage,
  removeFlashMessage
}: UnlockAccountProps) {
  const [formValues, setFormValues] = useState<FormValues>(defaultValues);
  const [validationForm, setValidationForm] =
    useState<FormValues>(defaultValues);
  const [hasPassword, setHasPassword] = useState(false);
  const [isValidCPF, setIsValidCPF] = useState(false);

  const verifyUserHasPassword = async (cpf: string) => {
    const responseHasPassword = await getUserHasPassword(cpf);

    if (responseHasPassword.hasPassword.message === 'user.invalid-cpf') {
      createFlashMessage({
        type: 'danger',
        message: FlashMessages.InvalidCPF
      });

      setValidationForm({
        ...validationForm,
        cpf: 'CPF inválido, favor verificar.'
      });

      setHasPassword(false);
      return;
    }

    if (responseHasPassword.hasPassword.message === 'user.not-blocked') {
      createFlashMessage({
        type: 'success',
        message: FlashMessages.UserNotBlocked
      });

      setHasPassword(false);
      return;
    }

    setIsValidCPF(true);

    setHasPassword(!!responseHasPassword?.hasPassword?.result?.hasPassword);
  };

  async function handleCpfChange(e: React.FormEvent<HTMLInputElement>) {
    removeFlashMessage();
    setValidationForm(defaultValues);
    setIsValidCPF(false);
    const value = (e.target as HTMLInputElement).value.slice(0);
    const onlyDigits = value.replace(/\D/g, '');

    if (onlyDigits.length === 11) {
      await verifyUserHasPassword(value);
    }

    return setFormValues({ ...formValues, cpf: value });
  }

  function handlePasswordChange(e: React.FormEvent<HTMLInputElement>) {
    const value = (e.target as HTMLInputElement).value.slice(0);
    return setFormValues({ ...formValues, password: value });
  }

  function isEmptyField(obj: FormValues) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const onlyDigits = obj.cpf.replace(/\D/g, '');
    if (onlyDigits.length === 0) {
      setValidationForm({
        ...validationForm,
        cpf: 'Campo está vazio, favor verificar.'
      });
      return true;
    }

    if (obj.email === '') {
      setValidationForm({
        ...validationForm,
        email: 'Campo está vazio, favor verificar.'
      });
      return true;
    }

    if (!emailRegex.test(obj.email)) {
      setValidationForm({
        ...validationForm,
        email: 'Email inválido, favor verificar.'
      });
      return true;
    }

    if (hasPassword && obj.password === '') {
      setValidationForm({
        ...validationForm,
        password: 'Campo está vazio, favor verificar.'
      });
      return true;
    }
    return false;
  }

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    const isEmpty = isEmptyField(formValues);
    if (!isEmpty) {
      unlockUserAccount(formValues);
    }
  }

  useEffect(() => {
    const onlyDigits = formValues.cpf.replace(/\D/g, '');

    if (onlyDigits.length !== 11) {
      setHasPassword(false);
    }
  }, [formValues.cpf]);

  return (
    <>
      <Helmet title={`Desbloquear conta | DEVstart`} />

      <Grid>
        <Spacer size='small' />

        <Row>
          <BackButton prevUrl='/' />
        </Row>
        <Spacer size='medium' />

        <div className='forgot-password-container'>
          <div className='form-forgot-password-container'>
            <p id='forgot-password-title'>Desbloquear conta</p>
            <Spacer size='small' />

            <p id='forgot-password-subtitle'>
              Sua conta está temporariamente bloqueada. Por motivos de
              segurança, sua conta foi bloqueada devido à inatividade. Para
              desbloqueá-la, por favor confirme seu CPF e Senha
            </p>
            <Spacer size='medium' />
            <form onSubmit={handleSubmit}>
              <Input
                label='CPF'
                name='cpf'
                hasMask={true}
                maskValue='999.999.999-99'
                placeholder='CPF'
                value={formValues.cpf}
                onChange={handleCpfChange}
                hasError={!!validationForm.cpf}
                messageError={validationForm.cpf}
              />

              <Input
                label='Email'
                name='email'
                placeholder='exemplo@exemplo.com'
                value={formValues.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setFormValues({ ...formValues, email: e.target.value });
                }}
                hasError={!!validationForm.email}
                messageError={validationForm.email}
              />

              {hasPassword && (
                <InputPassword
                  label='Senha'
                  name='password'
                  placeholder='Senha'
                  value={formValues.password}
                  onChange={handlePasswordChange}
                  hasError={!!validationForm.password}
                  messageError={validationForm.password}
                />
              )}
              <Spacer size='medium' />

              <div className='form-btn'>
                <Button
                  type='submit'
                  buttonType={ButtonTypes.Primary}
                  style={{ height: '48px', width: '100%' }}
                  disabled={!isValidCPF || formValues.email === ''}
                >
                  DESBLOQUEAR CONTA
                </Button>
              </div>
            </form>
          </div>
        </div>
      </Grid>
    </>
  );
}

UnlockAccount.displayName = 'UnlockAccountPage';

export default connect(null, mapDispatchToProps)(UnlockAccount);
