import React, { ReactNode } from 'react';
import { faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import ShowPassword from '@assets/icons/show-password';
import HidePassword from '@assets/icons/hide-password';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';

interface ActionRowProps {
  hasNotes: boolean;
  showConsole: boolean;
  showNotes: boolean;
  showInstructions: boolean;
  showTests: boolean;
  showPreviewPane: boolean;
  showPreviewPortal: boolean;
  togglePane: (pane: string) => void;
  hasPreview: boolean;
}

type ControlVisibilityButtonProps = {
  show: boolean;
  label: string;
  customIcon?: ReactNode;
  children?: ReactNode;
  onClick: () => void;
};

const ControlVisibilityButton = ({
  show,
  label,
  customIcon,
  children,
  onClick
}: ControlVisibilityButtonProps): JSX.Element => {
  const getIcon = () => {
    if (customIcon) {
      return customIcon;
    }
    return show ? (
      <ShowPassword fill='#484848' width={16} height={16} />
    ) : (
      <HidePassword fill='#717171' width={16} height={16} />
    );
  };

  return (
    <Button
      buttonType={ButtonTypes.Quinary}
      aria-expanded={show}
      onClick={onClick}
      style={{
        height: '26px',
        borderColor: show ? '#ADADAD' : '#EBEBEB',
        color: show ? '#484848' : '#717171'
      }}
    >
      {children}
      {label}
      {getIcon()}
    </Button>
  );
};

const ActionRow = ({
  hasNotes,
  togglePane,
  showNotes,
  showPreviewPane,
  showPreviewPortal,
  showConsole,
  showInstructions,
  showTests,
  hasPreview
}: ActionRowProps): JSX.Element => {
  const { t } = useTranslation();

  // sets screen reader text for the two preview buttons
  function getPreviewBtnsSrText() {
    // no preview open
    const previewBtnsSrText = {
      pane: t('aria.show-preview'),
      portal: t('aria.open-preview-in-new-window')
    };

    // preview open in main window
    if (showPreviewPane && !showPreviewPortal) {
      previewBtnsSrText.pane = t('aria.hide-preview');
      previewBtnsSrText.portal = t('aria.move-preview-to-new-window');

      // preview open in external window
    } else if (showPreviewPortal && !showPreviewPane) {
      previewBtnsSrText.pane = t('aria.move-preview-to-main-window');
      previewBtnsSrText.portal = t('aria.close-external-preview-window');
    }

    return previewBtnsSrText;
  }

  return (
    <div className='action-row'>
      <div className='tabs-row'>
        <div className='panel-display-instructions-test'>
          <ControlVisibilityButton
            show={showInstructions}
            label={t('learn.editor-tabs.instructions')}
            onClick={() => togglePane('showInstructions')}
          />

          <ControlVisibilityButton
            show={showTests}
            label={t('learn.editor-tabs.tests')}
            onClick={() => togglePane('showTests')}
          />

          <ControlVisibilityButton
            show={showConsole}
            label={t('learn.editor-tabs.console')}
            onClick={() => togglePane('showConsole')}
          />

          {hasPreview && (
            <>
              <ControlVisibilityButton
                show={showPreviewPane}
                label={t('learn.editor-tabs.preview')}
                onClick={() => togglePane('showPreviewPane')}
              />

              <ControlVisibilityButton
                show={showPreviewPortal}
                label=''
                customIcon={
                  <FontAwesomeIcon
                    icon={faWindowRestore}
                    style={{ fill: showPreviewPortal ? '#484848' : '#717171' }}
                  />
                }
                onClick={() => togglePane('showPreviewPortal')}
              >
                <span className='sr-only'>{getPreviewBtnsSrText().portal}</span>
              </ControlVisibilityButton>
            </>
          )}
        </div>

        <div className='panel-display-tabs'>
          {hasNotes && (
            <Button
              buttonType={ButtonTypes.Primary}
              aria-expanded={!!showNotes}
              onClick={() => togglePane('showNotes')}
            >
              {t('learn.editor-tabs.notes')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

ActionRow.displayName = 'ActionRow';

export default ActionRow;
