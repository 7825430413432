import React from 'react';

function Medal(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
      {...props}
    >
      <mask
        id='a'
        width={24}
        height={24}
        x={0}
        y={0}
        maskUnits='userSpaceOnUse'
        style={{
          maskType: 'alpha'
        }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z' />
      </mask>
      <g mask='url(#a)'>
        <path
          fill='#484848'
          d='m9.8 13.488.827-2.715-2.175-1.677h2.706L12 6.392l.842 2.704h2.706l-2.19 1.677.827 2.715L12 11.8l-2.2 1.688Zm-3.3 8.743v-7.158a7.15 7.15 0 0 1-1.475-2.294A7.36 7.36 0 0 1 4.5 10c0-2.092.727-3.865 2.18-5.32C8.136 3.228 9.909 2.5 12 2.5s3.865.727 5.32 2.18C18.772 6.136 19.5 7.909 19.5 10a7.36 7.36 0 0 1-.525 2.779 7.15 7.15 0 0 1-1.475 2.294v7.158L12 20.48l-5.5 1.75ZM12 16c1.667 0 3.083-.583 4.25-1.75C17.417 13.083 18 11.667 18 10c0-1.667-.583-3.083-1.75-4.25C15.083 4.583 13.667 4 12 4c-1.667 0-3.083.583-4.25 1.75C6.583 6.917 6 8.333 6 10c0 1.667.583 3.083 1.75 4.25C8.917 15.417 10.333 16 12 16Zm-4 4.035 4-1.073 4 1.073v-3.716a7.355 7.355 0 0 1-1.873.87A7.223 7.223 0 0 1 12 17.5c-.74 0-1.449-.104-2.127-.312A7.355 7.355 0 0 1 8 16.32v3.716Z'
        />
      </g>
    </svg>
  );
}

Medal.displayName = 'Medal';

export default Medal;
