/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Panel } from '@devstart/react-bootstrap';
import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';

import { isNacionalUser } from '@utils/general-functions';
import Medal from '@assets/icons/medal';
import { Link } from '../../helpers';
import { Themes } from '../../settings/theme';
import {
  apiLocation,
  courseSesiSenaiUrl,
  discordLocation,
  whatsappSupportNumber
} from '../../../../../config/env.json';

import {
  hardGoTo as navigate,
  openSignoutModal,
  changeTourStatus
} from '../../../redux/actions';
import { updateMyTheme } from '../../../redux/settings/actions';
import { userSelector } from '../../../redux/selectors';
import { User } from '../../../redux/prop-types';

import Profile from '../../../assets/icons/profile';
import ChevronDown2 from '../../../assets/icons/chevron-down-2';
import ChevronUp2 from '../../../assets/icons/chevron-up-2';
import WhatsApp from '../../../assets/icons/whatsapp';
import LockOpen from '../../../assets/icons/lock-open';
import House from '../../../assets/icons/house';
import AccountCircle from '../../../assets/icons/account-circle';
import Help2 from '../../../assets/icons/help-2';
import Discord from '../../../assets/icons/discord';
import Power from '../../../assets/icons/power';
import { AccessLevel } from '../../../utils/enums/access-levels';
import Emails from '../../../assets/icons/emails';

import './nav-links.css';

export interface NavLinksProps {
  fetchState?: { pending: boolean };
  user: User;
  onHide: () => void;
}

type LinkMapProps = {
  path?: string;
  title: string;
  icon: JSX.Element;
  adminOnly?: boolean;
  subitems?: LinkMapProps[];
  hideForNacionalUsers?: boolean;
  onClick?: () => void;
};

type SubitemSectionProps = {
  key: string;
  title: string;
  icon: JSX.Element;
  subitems: LinkMapProps[];
};

const mapStateToProps = createSelector(userSelector, (user: User) => ({
  user
}));

const mapDispatchToProps = {
  navigate,
  toggleNightMode: (theme: Themes) => updateMyTheme({ theme }),
  openSignoutModal,
  changeTourStatus
};

const SubitemSection = ({
  key,
  title,
  icon,
  subitems
}: SubitemSectionProps) => {
  const [toggle, setToggle] = useState(false);

  return (
    <Panel eventKey='1' bsClass='custom-accordion-panel' expanded={toggle}>
      <Panel.Title
        key={key}
        toggle
        className='nav-item'
        onClick={() => setToggle(prev => !prev)}
      >
        {icon}
        <span className='nav-link'>{title}</span>
        {toggle ? (
          <ChevronUp2 className='accordion-chevron' />
        ) : (
          <ChevronDown2 className='accordion-chevron' />
        )}
      </Panel.Title>

      <Panel.Body collapsible>
        {subitems?.map(subitem => (
          <Link
            key={subitem.title}
            className='nav-item'
            to={subitem.path ?? '#'}
            target='_blank'
            onClick={subitem.onClick}
          >
            {subitem.icon}
            <span className='nav-link'>{subitem.title}</span>
          </Link>
        ))}
      </Panel.Body>
    </Panel>
  );
};

function NavLinks({ fetchState, onHide, user }: NavLinksProps) {
  const { t } = useTranslation();

  const pending = fetchState?.pending;
  const ADMIN_USER = 'admin';

  const number = String(whatsappSupportNumber);
  const infoText = 'Encontrei um erro na plataforma do DEVstart';
  const whatsappURL = `https://api.whatsapp.com/send/?phone=${number}&text=${infoText}&type=phone_number&app_absent=0`;

  const linkMap: LinkMapProps[] = [
    {
      path: '/learn',
      title: t('buttons.start'),
      icon: <House />,
      adminOnly: false
    },
    {
      path: '/settings',
      title: t('buttons.profile'),
      icon: <AccountCircle />,
      adminOnly: false
    },
    {
      path: '/certificate-of-participation',
      title: t('buttons.certificates-of-participation'),
      icon: <Medal />,
      adminOnly: false,
      hideForNacionalUsers: true
    },
    {
      path: '/admin-users',
      title: t('buttons.admin-users'),
      icon: <Profile />,
      adminOnly: true,
      subitems: [
        {
          path: '/admin/emails',
          title: 'Envio de emails',
          icon: <Emails fill='#727272' width='16px' height='16px' />,
          onClick: onHide
        }
        // {
        //   path: '/admin/messages/message-dashboard',
        //   title: 'Envio de mensagens',
        //   icon: <Messages fill='#727272' width='16px' height='16px' />,
        //   onClick: onHide
        // }
      ]
    },
    {
      title: t('buttons.help'),
      icon: <Help2 />,
      subitems: [
        {
          path: String(discordLocation),
          title: t('buttons.i-have-a-question'),
          icon: <Discord />,
          onClick: () => {
            window.dataLayer.push({
              event: 'gtm.click',
              'gtm.elementClasses': 'help-link-discord'
            });
            onHide();
          }
        },
        {
          path: String(whatsappURL),
          title: t('buttons.report-an-error'),
          icon: <WhatsApp />,
          onClick: () => {
            window.dataLayer.push({
              event: 'gtm.click',
              'gtm.elementClasses': 'help-link-whatsApp'
            });
            onHide();
          }
        }
      ]
    }
  ];

  return pending ? (
    <div className='nav-skeleton' />
  ) : (
    <div className='nav-list'>
      <div className='logged-user'>
        <span className='user-name'>{user.name}</span>
        <span className='user-email'>{user.email}</span>
      </div>

      {user.access !== AccessLevel.COMPLETE && !isNacionalUser(user.access) && (
        <Link
          className='nav-item unlock-content'
          key='learn'
          to={courseSesiSenaiUrl}
          onClick={onHide}
        >
          <LockOpen />
          <span className='nav-link'>{t('buttons.unlock-content')}</span>
        </Link>
      )}

      <div className='items-section'>
        {linkMap.map(
          ({
            path,
            title,
            icon,
            adminOnly,
            subitems,
            hideForNacionalUsers
          }) => {
            if (hideForNacionalUsers && isNacionalUser(user.access)) {
              return <></>;
            }

            if (adminOnly && user.type !== ADMIN_USER) {
              return <></>;
            }

            if (subitems && subitems.length > 0) {
              return (
                <SubitemSection
                  key={title}
                  title={title}
                  icon={icon}
                  subitems={subitems}
                />
              );
            }

            return (
              <Link
                key={title}
                className='nav-item'
                to={path ?? '#'}
                onClick={onHide}
              >
                {icon}
                <span className='nav-link'>{title}</span>
              </Link>
            );
          }
        )}
      </div>
      <a
        key='sign-out'
        className='nav-item'
        href={`${apiLocation}/signout`}
        onClick={onHide}
      >
        <Power />
        <span className='nav-link'>{t('buttons.sign-out')}</span>
      </a>
    </div>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(NavLinks));
