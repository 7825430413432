import React from 'react';

function CornerRight(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='9'
      height='6'
      fill='none'
      viewBox='0 0 9 6'
      {...props}
    >
      <path
        fill={props.fill ?? '#D6D6D6'}
        d='M1.667 0v7h10s-10-2.333-10-7'
      ></path>
      <path
        fill={props.fill ?? '#fff'}
        d='M.667 1v6h7.857S.667 5 .667 1'
      ></path>
    </svg>
  );
}

CornerRight.displayName = 'CornerRight';

export default CornerRight;
