import React from 'react';

function Image(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_11061_10201)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M21.75 4.5H2.25C2.05109 4.5 1.86032 4.57902 1.71967 4.71967C1.57902 4.86032 1.5 5.05109 1.5 5.25V18.75C1.5 18.9489 1.57902 19.1397 1.71967 19.2803C1.86032 19.421 2.05109 19.5 2.25 19.5H21.75C21.9489 19.5 22.1397 19.421 22.2803 19.2803C22.421 19.1397 22.5 18.9489 22.5 18.75V5.25C22.5 5.05109 22.421 4.86032 22.2803 4.71967C22.1397 4.57902 21.9489 4.5 21.75 4.5ZM2.25 3C1.65326 3 1.08097 3.23705 0.65901 3.65901C0.237053 4.08097 0 4.65326 0 5.25L0 18.75C0 19.3467 0.237053 19.919 0.65901 20.341C1.08097 20.7629 1.65326 21 2.25 21H21.75C22.3467 21 22.919 20.7629 23.341 20.341C23.7629 19.919 24 19.3467 24 18.75V5.25C24 4.65326 23.7629 4.08097 23.341 3.65901C22.919 3.23705 22.3467 3 21.75 3H2.25Z'
          fill='#707070'
        />
        <path
          d='M15.9719 11.4689C16.0833 11.3578 16.2269 11.2846 16.3822 11.2596C16.5375 11.2345 16.6968 11.259 16.8374 11.3294L22.5029 14.2499V19.4999H1.50293V17.9999L5.47193 14.4689C5.59453 14.3468 5.7556 14.2708 5.92784 14.2538C6.10007 14.2369 6.27287 14.28 6.41693 14.3759L10.4069 17.0354L15.9719 11.4704V11.4689Z'
          fill='#707070'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.75293 10.5C7.0484 10.5 7.34098 10.4418 7.61397 10.3287C7.88695 10.2157 8.13499 10.0499 8.34392 9.84099C8.55285 9.63206 8.71859 9.38402 8.83166 9.11104C8.94473 8.83806 9.00293 8.54547 9.00293 8.25C9.00293 7.95453 8.94473 7.66194 8.83166 7.38896C8.71859 7.11598 8.55285 6.86794 8.34392 6.65901C8.13499 6.45008 7.88695 6.28434 7.61397 6.17127C7.34098 6.0582 7.0484 6 6.75293 6C6.15619 6 5.5839 6.23705 5.16194 6.65901C4.73998 7.08097 4.50293 7.65326 4.50293 8.25C4.50293 8.84674 4.73998 9.41903 5.16194 9.84099C5.5839 10.2629 6.15619 10.5 6.75293 10.5Z'
          fill='#707070'
        />
      </g>
      <defs>
        <clipPath id='clip0_11061_10201'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}

Image.displayName = 'Image';

export default Image;
