import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import React from 'react';

import RedirectHome from '../components/redirect-home';
import ShowCertificateOfParticipation from '../client-only-routes/show-certificate-of-participation';

function CertificateOfParticipation(): JSX.Element {
  return (
    <Router>
      <ShowCertificateOfParticipation
        path={withPrefix('/certificate-of-participation')}
      />
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <RedirectHome default={true} />
    </Router>
  );
}

CertificateOfParticipation.displayName = 'CertificateOfParticipation';

export default CertificateOfParticipation;
