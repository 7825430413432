import React from 'react';

function CornerLeft(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='7'
      fill='none'
      viewBox='0 0 12 7'
      {...props}
    >
      <path
        fill={props.fill ?? '#D6D6D6'}
        d='M10.667 0v7h-10s10-2.333 10-7'
      ></path>
      <path
        fill={props.fill ?? '#fff'}
        d='M11.667 1v6H3.81s7.857-2 7.857-6'
      ></path>
    </svg>
  );
}

CornerLeft.displayName = 'CornerLeft';

export default CornerLeft;
