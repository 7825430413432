export enum ButtonTypes {
  CTA = 'CTA',
  Outline = 'OUTLINE',
  Danger = 'DANGER',
  Light = 'LIGHT',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIIARY',
  Quaternary = 'QUATERNARY',
  Quinary = 'QUINARY',
  Senary = 'SENARY',

  // no style defined yet for the following types
  Septenary = 'SEPTENARY',
  Octonary = 'OCTONARY',
  Nonary = 'NONARY',
  Denary = 'DENARY'
}
