import React, { useState } from 'react';
import type { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import type { Dispatch } from 'redux';

import { deleteAccount, resetProgress } from '../../redux/settings/actions';
import DeleteModal from './delete-modal';
import ResetModal from './reset-modal';

import './danger-zone.css';

interface DangerZoneProps {
  readonly deleteAccount: () => void;
  readonly resetProgress: () => void;
  readonly t: TFunction;
}

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      deleteAccount,
      resetProgress
    },
    dispatch
  );

function DangerZone({ deleteAccount, resetProgress, t }: DangerZoneProps) {
  const [reset, setReset] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  function toggleResetModal(): void {
    setReset(prev => !prev);
  }

  function toggleDeleteModal(): void {
    setIsDeleteModalOpen(prev => !prev);
  }

  return (
    <div className='danger-zone-container'>
      <h4 className='danger-zone-title'>{t('settings.danger.heading')}</h4>

      <p className='danger-zone-delete-message'>
        {t('settings.danger.delete-all-data-permanently')}
      </p>

      <button className='highlight-on-delete' onClick={toggleDeleteModal}>
        {t('settings.danger.delete-my-account')}
      </button>

      <ResetModal
        onHide={toggleResetModal}
        reset={resetProgress}
        show={reset}
      />

      <DeleteModal
        deleteAccount={deleteAccount}
        onHide={toggleDeleteModal}
        show={isDeleteModalOpen}
      />
    </div>
  );
}

DangerZone.displayName = 'DangerZone';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(DangerZone));
