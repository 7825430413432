import React from 'react';

function Home(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      {...props}
    >
      <mask
        id='mask0_4242_2295'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='16'
        height='16'
      >
        <rect width='16' height='16' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_4242_2295)'>
        <path
          d='M2.99994 13.666V7.49288L1.75508 8.44801L1.15381 7.6506L7.99993 2.41602L14.846 7.6506L14.2448 8.43777L12.9999 7.49288V13.666H2.99994ZM3.99993 12.666H11.9999V6.73265L7.99993 3.68265L3.99993 6.73265V12.666Z'
          fill='#484848'
        />
      </g>
    </svg>
  );
}

Home.displayName = 'Home';

export default Home;
