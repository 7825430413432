import { Col, Grid, Row } from '@devstart/react-bootstrap';
import { graphql } from 'gatsby';
import type { TFunction } from 'i18next';
import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { createFlashMessage } from '@components/Flash/redux';
import { FlashMessages } from '@components/Flash/redux/flash-messages';

import VideoPlayer from '../../components/video-player';
import ChallengeDescription from '../../components/challenge-description';
import ChallengeTitle from '../../components/challenge-title';
import CompletionModal from '../../components/completion-modal';
import Hotkeys from '../../components/hotkeys';

import Spacer from '../../../../components/helpers/spacer';
import LearnLayout from '../../../../components/layouts/learn';

import {
  challengeMounted,
  openModal,
  submitChallenge,
  updateChallengeMeta,
  updateSolutionFormValues
} from '../../redux/actions';
import {
  completedChallengesIdsSelector,
  isChallengeCompletedSelector
} from '../../redux/selectors';

import {
  ChallengeMeta,
  ChallengeNode,
  User
} from '../../../../redux/prop-types';
import { userSelector } from '../../../../redux/selectors';
import SolutionForm from '../solution-form';
import { checkIsCertificateCheckpoint } from '../../../../../../utils/nacional-utils';
import { AccessLevel } from '../../../../utils/enums/access-levels';
import BreadCrumb from '../../components/bread-crumb';
import ContentChallengeLayout from '../../../../components/layouts/content-challenge-layout';

import './show.css';

const mapStateToProps = createSelector(
  isChallengeCompletedSelector,
  userSelector,
  completedChallengesIdsSelector,
  (
    isChallengeCompleted: boolean,
    user: User,
    completedChallengeIds: string[]
  ) => ({
    isChallengeCompleted,
    user,
    completedChallengeIds
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      updateChallengeMeta,
      challengeMounted,
      updateSolutionFormValues,
      openCompletionModal: () => openModal('completion'),
      openNacionalModal: () => openModal('nacionalCertificate'),
      submitChallenge,
      createFlashMessage
    },
    dispatch
  );

// Types
interface ProjectProps {
  challengeMounted: (arg0: string) => void;
  data: { challengeNode: ChallengeNode };
  isChallengeCompleted: boolean;
  openNacionalModal: () => void;
  submitChallenge: () => void;
  pageContext: {
    challengeMeta: ChallengeMeta;
  };
  t: TFunction;
  updateChallengeMeta: (arg0: ChallengeMeta) => void;
  updateSolutionFormValues: () => void;
  user: User;
  createFlashMessage: typeof createFlashMessage;
}

// Component
class Project extends Component<ProjectProps> {
  public static readonly displayName: string = 'Project';
  private _container: HTMLElement | null = null;

  constructor(props: ProjectProps) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta
    } = this.props;

    updateChallengeMeta({
      ...challengeMeta,
      title,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
    this._container?.focus();
  }

  componentDidUpdate(prevProps: ProjectProps): void {
    const {
      data: {
        challengeNode: {
          challenge: { title: prevTitle }
        }
      }
    } = prevProps;
    const {
      challengeMounted,
      data: {
        challengeNode: {
          challenge: { title: currentTitle, challengeType, helpCategory }
        }
      },
      pageContext: { challengeMeta },
      updateChallengeMeta
    } = this.props;
    if (prevTitle !== currentTitle) {
      updateChallengeMeta({
        ...challengeMeta,
        title: currentTitle,
        challengeType,
        helpCategory
      });
      challengeMounted(challengeMeta.id);
    }
  }

  handleSubmit({
    showCompletionModal
  }: {
    showCompletionModal: boolean;
  }): void {
    if (showCompletionModal) {
      this.props.submitChallenge();

      const isCheckpoint: boolean = checkIsCertificateCheckpoint(
        this.props.data.challengeNode.challenge
      );

      if (
        isCheckpoint &&
        (this.props.user.access === AccessLevel.CompleteNacional ||
          this.props.user.access === AccessLevel.LimitedNacional)
      ) {
        this.props.openNacionalModal();
      } else {
        this.props.createFlashMessage({
          type: 'success',
          message: FlashMessages.CongratsChallengeComplete,
          variables: { dismissible: true }
        });
      }
    }
  }

  render() {
    const {
      data: {
        challengeNode: {
          challenge,
          challenge: {
            fields: { blockName },
            challengeType,
            title,
            description,
            instructions,
            superBlock,
            certification,
            block,
            phase,
            videoId
          }
        }
      },
      isChallengeCompleted,
      pageContext: {
        challengeMeta: { nextChallengePath, prevChallengePath }
      },
      t,
      updateSolutionFormValues
    } = this.props;

    const blockNameTitle = `${t(
      `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
    )} - ${title}`;

    const breadCrumbItems = [
      {
        key: 'superblock-title',
        value: t(`intro:${superBlock}.title`),
        redirectsTo: `/learn/${superBlock}`
      },
      {
        key: 'superblock-phase',
        value: t(`intro:${superBlock}.phases.${phase}.title`),
        redirectsTo: `/learn/${superBlock}/#${block}`
      },
      {
        key: 'superblock-theme',
        value: t(`intro:${superBlock}.phases.${phase}.blocks.${block}.title`),
        redirectsTo: `/learn/${superBlock}/#${block}`
      },
      {
        key: 'superblock-challenge',
        value: title,
        redirectsTo: ''
      }
    ];

    return (
      <Hotkeys
        innerRef={(c: HTMLElement | null) => (this._container = c)}
        nextChallengePath={nextChallengePath}
        prevChallengePath={prevChallengePath}
      >
        <LearnLayout challenge={challenge}>
          <Helmet
            title={`${blockNameTitle} | ${t('learn.learn')} | DEVstart`}
          />

          <ContentChallengeLayout
            disabledContinueButton={!isChallengeCompleted}
          >
            <BreadCrumb breadCrumbItems={breadCrumbItems} />
            <Grid>
              <Row>
                <Col md={8} mdOffset={2} sm={10} smOffset={1} xs={12}>
                  <Spacer size='medium' />
                  <div className='container-project-solution'>
                    <ChallengeTitle>{title}</ChallengeTitle>
                    <div className='video-wrapper'>
                      <VideoPlayer videoId={videoId} />
                    </div>

                    <ChallengeDescription
                      description={description}
                      instructions={instructions}
                      style={{
                        padding: 0
                      }}
                    />
                    <SolutionForm
                      challengeType={challengeType}
                      description={description}
                      // eslint-disable-next-line @typescript-eslint/unbound-method
                      onSubmit={this.handleSubmit}
                      updateSolutionForm={updateSolutionFormValues}
                    />
                    <Spacer size='small' />
                  </div>
                </Col>
                <CompletionModal
                  block={block}
                  blockName={blockName}
                  certification={certification}
                  superBlock={superBlock}
                />
              </Row>
            </Grid>
          </ContentChallengeLayout>

          <Spacer size='medium' />
        </LearnLayout>
      </Hotkeys>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Project));

export const query = graphql`
  query ProjectChallenge($slug: String!) {
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        title
        description
        instructions
        challengeType
        helpCategory
        superBlock
        certification
        superOrder
        phaseOrder
        order
        phase
        challengeOrder
        block
        phase
        videoId
        translationPending
        fields {
          blockName
          slug
        }
      }
    }
  }
`;
