import { Router } from '@reach/router';
import { withPrefix } from 'gatsby';
import React from 'react';

import ShowSettings from '../client-only-routes/show-settings';

function Settings(): JSX.Element {
  return (
    <Router>
      <ShowSettings path={withPrefix('/settings')} />
    </Router>
  );
}

Settings.displayName = 'Settings';

export default Settings;
