import { graphql } from 'gatsby';
import React, { useState, useEffect, useRef } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { HandlerProps } from 'react-reflex';
import Media from 'react-responsive';
import { bindActionCreators, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import store from 'store';
import { editor } from 'monaco-editor';
import { isUserAccessAllowed } from '@utils/general-functions';
import { RegistrationResponseProps } from '@utils/ajax';
import { exerciseTour } from '@components/Tour/steps';
import { challengeTypes } from '../../../../utils/challenge-types';
import LearnLayout from '../../../components/layouts/learn';
import { MAX_MOBILE_WIDTH } from '../../../../../config/misc';
import { checkpoints } from '../../../../../utils/nacional-utils';

import {
  ChallengeFiles,
  ChallengeMeta,
  ChallengeNode,
  CompletedChallenge,
  ResizeProps,
  SavedChallengeFiles,
  Test,
  User
} from '../../../redux/prop-types';
import { isContained } from '../../../utils/is-contained';
import ChallengeDescription from '../components/challenge-description';
import Hotkeys from '../components/hotkeys';
import ResetModal from '../components/reset-modal';
import ChallengeTitle from '../components/challenge-title';
import CompletionModal from '../components/completion-modal';
import LimitedAccessModal from '../components/limited-access-modal';
import ShortcutsModal from '../components/shortcuts-modal';
import Notes from '../components/notes';
import Output from '../components/output';
import Preview, { type PreviewProps } from '../components/preview';
import SidePanel from '../components/side-panel';
import TestSuitePanel from '../components/test-suite-panel';

import {
  cancelTests,
  challengeMounted,
  createFiles,
  executeChallenge,
  initConsole,
  initTests,
  previewMounted,
  updateChallengeMeta,
  openModal,
  setEditorFocusability,
  setIsAdvancing
} from '../redux/actions';
import {
  challengeFilesSelector,
  completedChallengesIdsSelector,
  consoleOutputSelector,
  isChallengeCompletedSelector
} from '../redux/selectors';
import {
  savedChallengesSelector,
  userRegistrationStatusSelector,
  userSelector
} from '../../../redux/selectors';
import { AccessLevel } from '../../../utils/enums/access-levels';
import ToolPanel from '../components/tool-panel';
import ContentChallengeLayout from '../../../components/layouts/content-challenge-layout';
import MultifileEditor from './multifile-editor';
import DesktopLayout from './desktop-layout';

import './classic.css';
import '../components/test-frame.css';
import { mergeChallengeFiles } from './saved-challenges';
import EditorTabs from './editor-tabs';

const mapStateToProps = createStructuredSelector({
  challengeFiles: challengeFilesSelector,
  output: consoleOutputSelector,
  isChallengeCompleted: isChallengeCompletedSelector,
  savedChallenges: savedChallengesSelector,
  user: userSelector,
  completedChallengeIds: completedChallengesIdsSelector,
  userRegistrationStatus: userRegistrationStatusSelector
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      createFiles,
      initConsole,
      initTests,
      updateChallengeMeta,
      challengeMounted,
      executeChallenge,
      cancelTests,
      previewMounted,
      openModal,
      setEditorFocusability,
      setIsAdvancing
    },
    dispatch
  );

interface ShowClassicProps extends Pick<PreviewProps, 'previewMounted'> {
  readonly cancelTests: () => void;
  readonly challengeMounted: (arg0: string) => void;
  readonly createFiles: (arg0: ChallengeFiles | SavedChallengeFiles) => void;
  readonly data: { challengeNode: ChallengeNode };
  readonly executeChallenge: (options?: {
    showCompletionModal: boolean;
  }) => void;
  readonly challengeFiles: ChallengeFiles;
  readonly initConsole: (arg0: string) => void;
  readonly initTests: (tests: Test[]) => void;
  readonly isChallengeCompleted: boolean;
  readonly output: string[];
  readonly pageContext: {
    challengeMeta: ChallengeMeta;
  };
  readonly updateChallengeMeta: (arg0: ChallengeMeta) => void;
  readonly openModal: (modal: string) => void;
  readonly setEditorFocusability: (canFocus: boolean) => void;
  readonly setIsAdvancing: (arg: boolean) => void;
  readonly savedChallenges: CompletedChallenge[];
  readonly user: User;
  readonly completedChallengeIds: string[];
  readonly userRegistrationStatus: RegistrationResponseProps;
}

interface ReflexLayout {
  codePane: { flex: number };
  editorPane: { flex: number };
  instructionPane: { flex: number };
  notesPane: { flex: number };
  previewPane: { flex: number };
  testsPane: { flex: number };
  testSuitePane: { flex: number };
}

interface RenderEditorArgs {
  isMobileLayout: boolean;
  isUsingKeyboardInTablist: boolean;
}

const REFLEX_LAYOUT = 'challenge-layout';
const BASE_LAYOUT = {
  codePane: { flex: 1 },
  editorPane: { flex: 1 },
  instructionPane: { flex: 1 },
  testSuitePane: { flex: 0.3 },
  previewPane: { flex: 0.7 },
  notesPane: { flex: 0.7 },
  testsPane: { flex: 0.3 }
};

// Used to prevent monaco from stealing mouse/touch events on the upper jaw
// content widget so they can trigger their default actions. (Issue #46166)
const handleContentWidgetEvents = (e: MouseEvent | TouchEvent): void => {
  const target = e.target as HTMLElement;
  if (target?.closest('.editor-upper-jaw')) {
    e.stopPropagation();
  }
};

const StepPreview = ({
  disableIframe,
  previewMounted
}: Pick<PreviewProps, 'disableIframe' | 'previewMounted'>) => {
  return (
    <Preview
      className='full-height'
      disableIframe={disableIframe}
      previewMounted={previewMounted}
    />
  );
};

// The newline is important, because this text ends up in a `pre` element.
const defaultOutput = `
/**
* Your test output will go here
*/`;

export const firstCheckpoint = checkpoints.firstCheckpoint;

function ShowClassic({
  challengeFiles: reduxChallengeFiles,
  data: {
    challengeNode: {
      challenge,
      challenge: {
        id,
        challengeFiles: seedChallengeFiles,
        block,
        phase,
        title,
        description,
        instructions,
        certification,
        order,
        challengeOrder,
        fields: { tests, blockName, slug },
        challengeType,
        removeComments,
        hasEditableBoundaries,
        superBlock,
        helpCategory,
        usesMultifileEditor,
        notes
      }
    }
  },
  pageContext: {
    challengeMeta,
    challengeMeta: {
      isFirstStep,
      nextChallengePath,
      prevChallengePath
      // prevChallengeId
    }
  },
  createFiles,
  cancelTests,
  challengeMounted,
  initConsole,
  initTests,
  updateChallengeMeta,
  setIsAdvancing,
  savedChallenges,
  isChallengeCompleted,
  output,
  executeChallenge,
  previewMounted,
  user,
  completedChallengeIds,
  userRegistrationStatus
}: ShowClassicProps) {
  const { t } = useTranslation();
  const [resizing, setResizing] = useState(false);
  const [usingKeyboardInTablist, setUsingKeyboardInTablist] = useState(false);
  const containerRef = useRef<HTMLElement>();
  const editorRef = useRef<editor.IStandaloneCodeEditor>();
  const instructionsPanelRef = useRef<HTMLDivElement>(null);

  const blockNameTitle = `${t(
    `intro:${superBlock}.phases.${phase}.blocks.${block}.title`
  )}: ${title}`;
  const windowTitle = `${blockNameTitle} | DEVstart`;
  const showPreview =
    challengeType === challengeTypes.html ||
    challengeType === challengeTypes.modern ||
    challengeType === challengeTypes.multifileCertProject;

  const getLayoutState = () => {
    const reflexLayout = store.get(REFLEX_LAYOUT) as ReflexLayout;

    // Validate if user has not done any resize of the panes
    if (!reflexLayout) return BASE_LAYOUT;

    // Check that the layout values stored are valid (exist in base layout). If
    // not valid, it will fallback to the base layout values and be set on next
    // user resize.
    const isValidLayout = isContained(
      Object.keys(BASE_LAYOUT),
      Object.keys(reflexLayout)
    );

    return isValidLayout ? reflexLayout : BASE_LAYOUT;
  };

  // layout: Holds the information of the panes sizes for desktop view
  const [layout, setLayout] = useState(getLayoutState());

  const onStopResize = (event: HandlerProps) => {
    const { name, flex } = event.component.props;

    // Only interested in tracking layout updates for ReflexElement's
    if (!name) {
      setResizing(false);
      return;
    }

    // Forcing a state update with the value of each panel since on stop resize
    // is executed per each panel.
    if (typeof layout === 'object') {
      setLayout({
        ...layout,
        [name]: { flex }
      });
    }
    setResizing(false);

    store.set(REFLEX_LAYOUT, layout);
  };

  const setHtmlHeight = () => {
    const vh = String(window.innerHeight - 1);
    document.documentElement.style.height = vh + 'px';
  };
  const onResize = () => {
    setResizing(true);
  };
  const resizeProps: ResizeProps = {
    onResize,
    onStopResize
  };

  const updateUsingKeyboardInTablist = (
    usingKeyboardInTablist: boolean
  ): void => {
    setUsingKeyboardInTablist(usingKeyboardInTablist);
  };

  useEffect(() => {
    initializeComponent(title);
    // Bug fix for the monaco content widget and touch devices/right mouse
    // click. (Issue #46166)
    document.addEventListener('mousedown', handleContentWidgetEvents, true);
    document.addEventListener('contextmenu', handleContentWidgetEvents, true);
    document.addEventListener('touchstart', handleContentWidgetEvents, true);
    document.addEventListener('touchmove', handleContentWidgetEvents, true);
    document.addEventListener('touchend', handleContentWidgetEvents, true);

    window.addEventListener('resize', setHtmlHeight);
    setHtmlHeight();

    updateUsingKeyboardInTablist(false);

    return () => {
      createFiles([]);
      cancelTests();
      document.removeEventListener(
        'mousedown',
        handleContentWidgetEvents,
        true
      );
      document.removeEventListener(
        'contextmenu',
        handleContentWidgetEvents,
        true
      );
      document.removeEventListener(
        'touchstart',
        handleContentWidgetEvents,
        true
      );
      document.removeEventListener(
        'touchmove',
        handleContentWidgetEvents,
        true
      );
      document.removeEventListener('touchend', handleContentWidgetEvents, true);
      window.removeEventListener('resize', setHtmlHeight);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeComponent = (title: string): void => {
    initConsole('');

    const savedChallenge = savedChallenges?.find(challenge => {
      return challenge.id === challengeMeta.id;
    });

    createFiles(
      mergeChallengeFiles(seedChallengeFiles, savedChallenge?.challengeFiles)
    );

    initTests(tests);

    updateChallengeMeta({
      ...challengeMeta,
      title,
      phase,
      removeComments: removeComments !== false,
      challengeType,
      helpCategory
    });
    challengeMounted(challengeMeta.id);
    setIsAdvancing(false);
  };

  const renderInstructionsPanel = () => {
    return (
      <SidePanel
        block={block}
        challengeTitle={<ChallengeTitle type='type-2'>{title}</ChallengeTitle>}
        challengeDescription={
          <ChallengeDescription
            block={block}
            description={description}
            instructions={instructions}
            superBlock={superBlock}
          />
        }
        instructionsPanelRef={instructionsPanelRef}
        superBlock={superBlock}
      />
    );
  };

  const renderTestSuitePanel = () => {
    return <TestSuitePanel />;
  };

  const renderEditor = ({
    isMobileLayout,
    isUsingKeyboardInTablist
  }: RenderEditorArgs) => {
    return (
      reduxChallengeFiles && (
        <div
          id='multifile-editor-container'
          style={{
            height: '100%'
          }}
        >
          <EditorTabs />
          <MultifileEditor
            challengeFiles={reduxChallengeFiles}
            containerRef={containerRef}
            description={description}
            editorRef={editorRef}
            initialTests={tests}
            isMobileLayout={isMobileLayout}
            isUsingKeyboardInTablist={isUsingKeyboardInTablist}
            resizeProps={resizeProps}
            title={title}
            usesMultifileEditor={usesMultifileEditor}
            currentChallengeId={id}
            challenge={challenge}
          />
        </div>
      )
    );
  };

  const renderCommandPanel = () => {
    return (
      <ToolPanel
        isLastChallenge={isLastChallenge()}
        challenge={challenge}
        user={user}
      />
    );
  };

  const isLastChallenge = () => {
    if (user.access === AccessLevel.LIMITED) {
      if (order === 5 && challengeOrder === 8) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const isAllowed = isUserAccessAllowed(
    challengeMeta,
    completedChallengeIds,
    challenge,
    user,
    userRegistrationStatus?.Row?.length > 0
  );

  const handleFinishTour = () => {
    driver.destroy();
  };

  const driver = exerciseTour(handleFinishTour);

  const handleTourStart = () => {
    driver.drive();
  };

  return (
    <Hotkeys
      challengeType={challengeType}
      executeChallenge={executeChallenge}
      innerRef={containerRef}
      instructionsPanelRef={instructionsPanelRef}
      nextChallengePath={nextChallengePath}
      prevChallengePath={prevChallengePath}
      usesMultifileEditor={usesMultifileEditor}
      {...(editorRef && { editorRef: editorRef })}
    >
      <LearnLayout
        isHandsOnChallenge={true}
        onTourStart={handleTourStart}
        challenge={challenge}
      >
        <Helmet title={windowTitle} />

        <ContentChallengeLayout disabledContinueButton={!isChallengeCompleted}>
          <Media maxWidth={MAX_MOBILE_WIDTH}>
            <DesktopLayout
              block={block}
              phase={phase}
              superBlock={superBlock}
              title={title}
              slug={slug}
              challengeFiles={reduxChallengeFiles}
              challengeType={challengeType}
              editor={renderEditor({
                isMobileLayout: false,
                isUsingKeyboardInTablist: usingKeyboardInTablist
              })}
              testSuite={renderTestSuitePanel()}
              commandPanel={renderCommandPanel()}
              hasEditableBoundaries={hasEditableBoundaries}
              hasNotes={!!notes}
              hasPreview={showPreview}
              instructions={renderInstructionsPanel()}
              isFirstStep={isFirstStep}
              layoutState={layout}
              notes={<Notes notes={notes} />}
              preview={
                <StepPreview
                  disableIframe={resizing}
                  previewMounted={previewMounted}
                />
              }
              resizeProps={resizeProps}
              testOutput={
                <Output defaultOutput={defaultOutput} output={output} />
              }
              windowTitle={windowTitle}
              isAllowedChallenge={isAllowed}
            />
          </Media>

          <Media minWidth={MAX_MOBILE_WIDTH + 1}>
            <DesktopLayout
              block={block}
              phase={phase}
              superBlock={superBlock}
              title={title}
              slug={slug}
              challengeFiles={reduxChallengeFiles}
              challengeType={challengeType}
              editor={renderEditor({
                isMobileLayout: false,
                isUsingKeyboardInTablist: usingKeyboardInTablist
              })}
              testSuite={renderTestSuitePanel()}
              commandPanel={renderCommandPanel()}
              hasEditableBoundaries={hasEditableBoundaries}
              hasNotes={!!notes}
              hasPreview={showPreview}
              instructions={renderInstructionsPanel()}
              isFirstStep={isFirstStep}
              layoutState={layout}
              notes={<Notes notes={notes} />}
              preview={
                <StepPreview
                  disableIframe={resizing}
                  previewMounted={previewMounted}
                />
              }
              resizeProps={resizeProps}
              testOutput={
                <Output defaultOutput={defaultOutput} output={output} />
              }
              windowTitle={windowTitle}
              isAllowedChallenge={isAllowed}
            />
          </Media>

          <CompletionModal
            block={block}
            blockName={blockName}
            certification={certification}
            superBlock={superBlock}
          />
          <ResetModal />
          <LimitedAccessModal />
          <ShortcutsModal />
        </ContentChallengeLayout>
      </LearnLayout>
    </Hotkeys>
  );
}

ShowClassic.displayName = 'ShowClassic';

export default connect(mapStateToProps, mapDispatchToProps)(ShowClassic);

export const query = graphql`
  query ClassicChallenge($slug: String!) {
    challengeNode(challenge: { fields: { slug: { eq: $slug } } }) {
      challenge {
        block
        phase
        title
        description
        id
        hasEditableBoundaries
        instructions
        notes
        superOrder
        phaseOrder
        order
        challengeOrder
        removeComments
        challengeType
        helpCategory
        superBlock
        phase
        certification
        translationPending
        fields {
          blockName
          slug
          tests {
            text
            testString
          }
        }
        usesMultifileEditor
        challengeFiles {
          fileKey
          ext
          name
          contents
          head
          tail
          editableRegionBoundaries
          history
        }
      }
    }
  }
`;
