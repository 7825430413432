/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { styled } from '@mui/material/styles';
import i18next from 'i18next';
import Terminal from '@assets/icons/terminal';
import CornerLeft from '@assets/icons/corner-left';
import CornerRight from '@assets/icons/corner-right';
import { sortChallengeFiles } from '../../../../../utils/sort-challengefiles';
import { ChallengeFile, ChallengeFiles } from '../../../redux/prop-types';
import { toggleVisibleEditor } from '../redux/actions';
import {
  visibleEditorsSelector,
  challengeFilesSelector
} from '../redux/selectors';

type VisibleEditors = {
  [key: string]: boolean;
};
interface EditorTabsProps {
  challengeFiles: ChallengeFiles;
  toggleVisibleEditor: typeof toggleVisibleEditor;
  visibleEditors: VisibleEditors;
}

const mapStateToProps = createSelector(
  visibleEditorsSelector,
  challengeFilesSelector,
  (visibleEditors: VisibleEditors, challengeFiles: ChallengeFiles) => ({
    visibleEditors,
    challengeFiles
  })
);

const mapDispatchToProps = {
  toggleVisibleEditor
};

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  background: var(--Pallets-Neutral-15, #f5f5f5);
  border-radius: 6px 6px 0px 0px;
  height: 40px;
  max-height: 40px;
  padding: 0px 12px;
  padding-top: 4px;
  border-bottom: none;
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar {
    height: 0px;
  }
`;

const PinnedTab = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding-right: 12px;
  // border-bottom-right-radius: 6px;
  background: var(--Pallets-Neutral-15, #f5f5f5);
  border-bottom: 1px solid var(--Pallets-Neutral-50, #d6d6d6);

  span {
    color: var(--Pallets-Neutral-700, #484848);
    font-family: 'Space Mono';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

const Tab = styled('button')<{ isSelected: boolean; indexTab: number }>`
  flex: 1;
  border: none;
  background-color: ${({ isSelected }) =>
    isSelected ? 'white' : 'var(--Pallets-Neutral-15, #f5f5f5)'};
  cursor: pointer;
  outline: none;
  transition: 0.3s ease-in-out;
  max-width: 120px;
  padding: 0px 10px;
  position: relative;

  color: var(--Pallets-Neutral-700, #484848);
  font-family: 'Space Mono';
  font-size: 14px;
  font-style: normal;
  font-weight: ${({ isSelected }) => (isSelected ? 700 : 400)};
  line-height: normal;

  border-radius: ${({ isSelected }) =>
    isSelected ? '6px 6px 0px 0px' : 'none'};
  border: ${({ isSelected }) =>
    isSelected
      ? '1px solid var(--Pallets-Neutral-50, #D6D6D6)'
      : '1px solid transparent'};
  border-bottom: 1px solid
    ${({ isSelected }) => (isSelected ? 'white' : '#d6d6d6')};

  &:hover {
    background-color: white;
    color: var(--Pallets-Neutral-700, #484848);
    border: 1px solid var(--Pallets-Neutral-50, #d6d6d6);
    outline: none;
    border-bottom: 1px solid white;
    font-weight: 700;
  }

  &:focus {
    background-color: white;
    color: var(--Pallets-Neutral-700, #484848);
    border: 1px solid var(--Pallets-Neutral-50, #d6d6d6);
    outline: none;
    border-bottom: 1px solid white;
    font-weight: 700;
  }

  &:active {
    background-color: white;
    color: var(--Pallets-Neutral-700, #484848);
    border: 1px solid var(--Pallets-Neutral-50, #d6d6d6);
    outline: none;
    border-bottom: 1px solid white;
    font-weight: 700;
  }

  svg.corner-left {
    position: absolute;
    top: 100%;
    top: calc(100% + 1px);
    left: 2px;
    transform: translate(-100%, -100%);
    z-index: ${({ indexTab }) => Number(indexTab) + 1};
    border-bottom: 1px solid white;
    border-right: 1px solid white;
  }

  svg.corner-right {
    position: absolute;
    top: calc(100% + 1px);
    right: 2px;
    transform: translate(100%, -100%);
    z-index: ${({ indexTab }) => Number(indexTab) + 1};
    border-bottom: 1px solid white;
    border-left: 1px solid white;
  }
`;

class EditorTabs extends Component<EditorTabsProps> {
  public static readonly displayName: string = 'EditorTabs';
  render() {
    const { challengeFiles, toggleVisibleEditor, visibleEditors } = this.props;
    const files: ChallengeFile[] = sortChallengeFiles(challengeFiles);
    const isMoreThanOneFile = files.length > 1;
    return (
      <Container>
        <PinnedTab>
          <Terminal width={24} height={24} />
          <span>Editor</span>
        </PinnedTab>

        {isMoreThanOneFile &&
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
          sortChallengeFiles(challengeFiles).map(
            (challengeFile: ChallengeFile, index: number) => (
              <Tab
                aria-expanded={visibleEditors[challengeFile.fileKey] ?? 'false'}
                key={challengeFile.fileKey}
                data-cy={`editor-tab-${challengeFile.fileKey}`}
                onClick={() => toggleVisibleEditor(challengeFile.fileKey)}
                isSelected={visibleEditors[challengeFile.fileKey] ?? false}
                indexTab={index}
              >
                {`${challengeFile.name}.${challengeFile.ext}`}{' '}
                <span className='sr-only'>
                  {i18next.t('learn.editor-tabs.editor')}
                </span>
                {visibleEditors[challengeFile.fileKey] && (
                  <>
                    <CornerLeft className='corner-left' />
                    <CornerRight className='corner-right' />
                  </>
                )}
              </Tab>
            )
          )}
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditorTabs);
