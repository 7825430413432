import React, { Component } from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import { createSelector } from 'reselect';
import { fetchUser } from '../../redux/actions';
import {
  isSignedInSelector,
  userFetchStateSelector,
  userSelector
} from '../../redux/selectors';
import Header from '../Header';
import { User, UserFetchState } from '../../redux/prop-types';
import Footer from '../Footer';

interface CertificationProps {
  children?: React.ReactNode;
  fetchUser: () => void;
  isSignedIn?: boolean;
  user: User;
  fetchState: UserFetchState;
}

const mapStateToProps = createSelector(
  isSignedInSelector,
  userSelector,
  userFetchStateSelector,
  (isSignedIn: boolean, user: User, fetchState: UserFetchState) => ({
    isSignedIn,
    user,
    fetchState
  })
);

const mapDispatchToProps = { fetchUser };

class CertificationLayout extends Component<CertificationProps> {
  static readonly displayName = 'CertificationLayout';
  componentDidMount() {
    const { isSignedIn, fetchUser } = this.props;
    if (!isSignedIn) {
      fetchUser();
    }
  }

  render(): JSX.Element {
    const { children, user, fetchState } = this.props;

    return (
      <>
        <Helmet bodyAttributes={{ class: 'light-palette' }} />
        <Header fetchState={fetchState} user={user} />
        {children}
        <Footer />
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CertificationLayout);
