import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { navigate } from 'gatsby-link';
import ChallengeNotAllowedModal from '@components/modals/challenge-not-allowed-modal';
import FloatButton from '@ui/FloatButton';
import { isAllowedChallenge } from '../../templates/Challenges/redux/actions';
import {
  challengeMetaSelector,
  isCompletedChallengesLoading
} from '../../templates/Challenges/redux/selectors';
import { Loader } from '../../components/helpers';
import {
  userFetchStateSelector,
  isSignedInSelector,
  userSelector
} from '../../redux/selectors';
import createRedirect from '../create-redirect';
import { Challenge, ChallengeMeta, User } from '../../redux/prop-types';
import { fetchCourseCodeInfo } from '../../redux/actions';
import { AccessLevel } from '../../utils/enums/access-levels';
import { apiLocation } from '../../../../config/env.json';

import './prism.css';
import './prism-night.css';
import 'react-reflex/styles.css';
import './learn.css';

type FetchState = {
  pending: boolean;
  complete: boolean;
  errored: boolean;
};

const mapStateToProps = createSelector(
  userFetchStateSelector,
  isSignedInSelector,
  userSelector,
  isCompletedChallengesLoading,
  challengeMetaSelector,
  (
    fetchState: FetchState,
    isSignedIn,
    user: User,
    isCompletedChallengesLoading: boolean,
    challengeMeta: ChallengeMeta
  ) => ({
    fetchState,
    isSignedIn,
    user,
    isCompletedChallengesLoading,
    challengeMeta
  })
);

const mapDispatchToProps = {
  // tryToShowDonationModal
  fetchCourseCodeInfo,
  isAllowedChallenge
};

const RedirectSubscription = createRedirect('/data-complement');

type LearnLayoutProps = {
  readonly isSignedIn?: boolean;
  readonly fetchState: FetchState;
  readonly user: User;
  readonly isHandsOnChallenge?: boolean;
  // tryToShowDonationModal: () => void;
  readonly fetchCourseCodeInfo: (data: { userUF: string }) => void;
  readonly isCompletedChallengesLoading: boolean;
  readonly challenge?: Challenge;
  readonly isAllowedChallenge: (challenge: Challenge) => void;
  readonly onTourStart?: () => void;
  readonly children?: React.ReactNode;
  readonly challengeMeta: ChallengeMeta;
};

const getUserStateAbbreviations = (source: string) => {
  return source.split('_')[1];
};

function LearnLayout({
  isSignedIn,
  fetchState,
  user,
  isHandsOnChallenge = false,
  // tryToShowDonationModal,
  fetchCourseCodeInfo,
  isCompletedChallengesLoading,
  challenge,
  isAllowedChallenge,
  challengeMeta,
  onTourStart,
  children
}: LearnLayoutProps): JSX.Element {
  useEffect(() => {
    if (
      user.access === AccessLevel.LimitedNacional ||
      user.access === AccessLevel.CompleteNacional
    ) {
      fetchCourseCodeInfo({ userUF: getUserStateAbbreviations(user.source) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      const metaTag = document.querySelector(`meta[name="robots"]`);
      if (metaTag) {
        metaTag.remove();
      }
    };
  }, []);

  useEffect(() => {
    if (isSignedIn && user?.isBlockedByInactivity === true) {
      void navigate(`${apiLocation}/signout`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      challenge &&
      !isCompletedChallengesLoading &&
      challengeMeta?.id !== ''
    ) {
      isAllowedChallenge(challenge);
    }
  }, [
    isCompletedChallengesLoading,
    challenge,
    isAllowedChallenge,
    challengeMeta
  ]);

  if (!isSignedIn) {
    void navigate('/');
    return <Loader fullScreen={true} />;
  }

  if (fetchState.pending && !fetchState.complete) {
    return <Loader fullScreen={true} />;
  }

  if (isSignedIn && !user.subscription) {
    return <RedirectSubscription />;
  }

  return (
    <>
      <Helmet>
        <meta content='noindex' name='robots' />
      </Helmet>
      <main id='learn-app-wrapper'>
        <FloatButton
          hasTourItem={isHandsOnChallenge}
          onTourStart={onTourStart}
        />
        {children}
      </main>
      {/* <DonateModal /> */}
      <ChallengeNotAllowedModal />
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LearnLayout);
