import React, { ReactNode, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Overlay from 'react-overlays/esm/Overlay';
import { Tooltip } from '@devstart/react-bootstrap';

import Discord from '../../../assets/icons/discord';
import HelpFilled from '../../../assets/icons/help-filled';
import WhatsApp from '../../../assets/icons/whatsapp';
import Tutorial from '../../../assets/icons/tutorial';
import ModalRedirectDiscord from './components/modal-redirect-discord';
import ModalRedirectWhatsapp from './components/modal-redirect-whatsapp';

import './float-button.css';

type Content = {
  icon: ReactNode;
  text: string;
  url?: string;
  action?: () => void;
  title?: string;
};

type FloatButtonProps = {
  readonly contents?: Content[];
  readonly hasTourItem?: boolean;
  readonly onTourStart?: () => void;
};

function FloatButton({
  contents,
  hasTourItem = false,
  onTourStart
}: FloatButtonProps): JSX.Element {
  const [shown, setShown] = useState(false);
  const [openModalRedirect, setOpenModalRedirect] = useState<
    null | 'discord' | 'whatsApp'
  >(null);

  const triggerRef = useRef(null);
  const containerRef = useRef(null);

  const { t } = useTranslation();

  const infoText = t('buttons.error-message-whatsapp');

  const handleOpenModal = (modal: 'discord' | 'whatsApp') => {
    setOpenModalRedirect(modal);
    setShown(false);
  };

  const handleCloseModal = () => {
    setOpenModalRedirect(null);
  };

  const defaultContent: Content[] = [
    ...(hasTourItem
      ? [
          {
            icon: <Tutorial />,
            text: t('buttons.tour'),
            action: onTourStart
          }
        ]
      : []),
    {
      icon: <Discord />,
      text: t('buttons.i-have-a-question'),
      action: () => handleOpenModal('discord')
    },
    {
      icon: <WhatsApp />,
      text: t('buttons.report-an-error'),
      action: () => handleOpenModal('whatsApp')
    }
  ];

  const displayContent = contents ?? defaultContent;

  return (
    <div className='floating-button-container'>
      <button ref={triggerRef} onClick={() => setShown(!shown)}>
        <HelpFilled width={36} height={36} />
      </button>

      <div className='floating-button-overlay-container' ref={containerRef}>
        <Overlay
          show={shown}
          rootClose
          container={containerRef}
          placement='top-end'
          offset={[0, 14]}
          target={triggerRef}
          onHide={() => setShown(false)}
        >
          {({ props }) => (
            <Tooltip id='floating-menu-tooltip' className='bottom-end'>
              <div {...props} className='menu-overlay'>
                <div className='menu-overlay-content'>
                  {displayContent.map(({ icon, text, action, url }) => (
                    <a
                      key={text}
                      className='item'
                      href={url}
                      target={url ?? '#'}
                      rel='noopener noreferrer'
                      onClick={() => {
                        setShown(false);
                        action?.();
                      }}
                    >
                      {icon}
                      <span>{text}</span>
                    </a>
                  ))}
                </div>
              </div>
            </Tooltip>
          )}
        </Overlay>
      </div>

      <ModalRedirectDiscord
        isOpen={openModalRedirect === 'discord'}
        close={handleCloseModal}
      />

      <ModalRedirectWhatsapp
        isOpen={openModalRedirect === 'whatsApp'}
        close={handleCloseModal}
        infoText={infoText}
      />
    </div>
  );
}

FloatButton.displayName = 'FloatButton';

export default FloatButton;
