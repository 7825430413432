import * as React from 'react';
import { renderToString } from 'react-dom/server';
import { Config, driver, Driver, DriveStep } from 'driver.js';
import ArrowLeft from '@assets/icons/arrow-left';
import ArrowRight from '@assets/icons/arrow-right';
import CheckedCurriculum from '@assets/icons/checked-curriculum';
import i18n from '../../../i18n/config';

import './steps.css';
import 'driver.js/dist/driver.css';
const prevIconString: string = renderToString(<ArrowLeft iconcolor='black' />);

const nextIconString: string = renderToString(
  <ArrowRight iconcolor='black' width='24' height='24' />
);

const checkIconString: string = renderToString(
  <CheckedCurriculum iconcolor='black' />
);

const settings: Config = {
  allowClose: false,
  stagePadding: 5,
  stageRadius: 15,
  popoverOffset: 20,
  doneBtnText: checkIconString,
  onPopoverRender: (popover, { config, state }) => {
    const currentStep =
      typeof state.activeIndex === 'number' ? state.activeIndex + 1 : 0;
    const lastStep = config.steps?.length;

    if (currentStep !== lastStep) {
      popover.nextButton.innerHTML = nextIconString;
    }
    popover.previousButton.innerHTML = prevIconString;
  }
};

function t(key: string) {
  return i18n.t(key);
}

const exerciseTour = (handleFinishTour: () => void): Driver => {
  const steps: DriveStep[] = [
    {
      popover: {
        title: t('tour.exercise.steps.intro.title') || '',
        description: t('tour.exercise.steps.intro.description') || '',
        popoverClass: 'exercise-intro-popover',
        prevBtnText: t('tour.modal.button.not-now'),
        nextBtnText: t('tour.modal.button.start-tour'),
        onPopoverRender: popover => {
          popover.nextButton.innerHTML = t('tour.modal.button.start-tour');
          popover.previousButton.innerHTML = t('tour.modal.button.not-now');
          popover.previousButton.classList.remove(
            'driver-popover-btn-disabled'
          );
          popover.previousButton.removeAttribute('disabled');
        },
        onPrevClick: () => {
          handleFinishTour();
        }
      }
    },
    {
      element: '#instructions-panel-info',
      popover: {
        description: t('tour.exercise.steps.instructions') || '',
        popoverClass: 'instructions-panel-popover'
      }
    },
    {
      element: '#instructions-panel-tests',
      popover: {
        description: t('tour.exercise.steps.tests') || '',
        popoverClass: 'instructions-panel-popover'
      }
    },
    {
      element: '#div-reflex-editor',
      popover: {
        description: t('tour.exercise.steps.editor') || '',
        popoverClass: 'instructions-panel-popover'
      }
    },

    {
      element: '.output-text',
      popover: {
        description: t('tour.exercise.steps.console') || '',
        popoverClass: 'intro-popover',
        align: 'end'
      }
    },
    {
      element: '#run-code-button',
      popover: {
        description: t('tour.exercise.steps.run-code') || '',
        popoverClass: 'instructions-panel-popover',
        align: 'end'
      }
    },
    {
      element: '#restart-code-button',
      popover: {
        description: t('tour.exercise.steps.restart') || '',
        popoverClass: 'exercise-restart-popover',
        align: 'end'
      }
    },
    {
      element: '.challenge-preview',
      popover: {
        description: t('tour.exercise.steps.preview') || '',
        popoverClass: 'exercise-restart-popover',
        align: 'end'
      }
    },
    {
      element: '.step-for-tour-splitter-1',
      popover: {
        description: t('tour.exercise.steps.resize') || '',
        popoverClass: 'instructions-panel-popover'
      }
    },
    {
      element: '.step-for-tour-splitter-2',
      popover: {
        description: t('tour.exercise.steps.resize') || '',
        popoverClass: 'instructions-panel-popover'
      }
    },
    {
      element: '.step-for-tour-splitter-3',
      popover: {
        description: t('tour.exercise.steps.resize') || '',
        popoverClass: 'instructions-panel-popover'
      }
    },
    {
      element: '.panel-display-instructions-test',
      popover: {
        description: t('tour.exercise.steps.actions') || '',
        popoverClass: 'instructions-panel-popover',
        align: 'end'
      }
    },
    {
      element: '.floating-button-container',
      popover: {
        description: t('tour.exercise.steps.help') || '',
        popoverClass: 'instructions-panel-popover',
        align: 'end',
        onCloseClick: () => {
          handleFinishTour();
        },
        onNextClick: () => {
          handleFinishTour();
        }
      }
    }
  ];

  const driverObj = driver({
    ...settings,
    steps,
    onCloseClick: () => {
      handleFinishTour();
    }
  });

  return driverObj;
};

export { exerciseTour };
