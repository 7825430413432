import { Grid, Row } from '@devstart/react-bootstrap';
import React, { useRef } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import envData from '../../../config/env.json';
import { createFlashMessage } from '../components/Flash/redux';
import { Loader, Spacer } from '../components/helpers';
import Certification from '../components/settings/certification';
import { hardGoTo as navigate } from '../redux/actions';
import { User } from '../redux/prop-types';
import {
  isSignedInSelector,
  signInLoadingSelector,
  userSelector
} from '../redux/selectors';
import { verifyCert, updateMyHonesty } from '../redux/settings/actions';
import './show-settings.css';
import BreadCrumb from '../templates/Challenges/components/bread-crumb';
import { isNacionalUser } from '../utils/general-functions';
import Honesty from '../components/settings/honesty';

const { apiLocation } = envData;

type ShowCertificateOfParticipationProps = {
  readonly isSignedIn: boolean;
  readonly showLoading: boolean;
  readonly user: User;
  readonly createFlashMessage: typeof createFlashMessage;
  readonly navigate: (location: string) => void;
  readonly verifyCert: () => void;
  readonly updateIsHonest: () => void;
};

const mapStateToProps = createSelector(
  signInLoadingSelector,
  userSelector,
  isSignedInSelector,
  (showLoading: boolean, user: User, isSignedIn) => ({
    showLoading,
    user,
    isSignedIn
  })
);

const mapDispatchToProps = {
  createFlashMessage,
  navigate,
  verifyCert,
  updateIsHonest: updateMyHonesty
};

export function ShowCertificateOfParticipation(
  props: ShowCertificateOfParticipationProps
): JSX.Element {
  const { t } = useTranslation();
  const {
    createFlashMessage,
    isSignedIn,
    user: {
      access,
      completedChallenges,
      isLogicaDeProgramacaoCert,
      isHtmlCssJavascriptCert,
      isReactCert,
      isHonest,
      username
    },
    navigate,
    showLoading,
    verifyCert,
    updateIsHonest
  } = props;
  const isSignedInRef = useRef(isSignedIn);

  if (showLoading) {
    return <Loader fullScreen={true} />;
  }

  if (!isSignedInRef.current) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }

  if (isNacionalUser(access)) {
    navigate(`${apiLocation}/signin`);
    return <Loader fullScreen={true} />;
  }

  const breadCrumbItems = [
    {
      key: 'certificate-of-participation',
      value: 'Atestado de participação',
      redirectsTo: '/certificate-of-participation'
    }
  ];

  return (
    <>
      <Helmet
        title={`${t('buttons.certificates-of-participation')} | DEVstart`}
      />

      <BreadCrumb breadCrumbItems={breadCrumbItems} />

      <Grid className='settings-wrapper'>
        <Row>
          <main className='container-settings'>
            <h1
              className='title-section'
              style={{ overflowWrap: 'break-word' }}
            >
              {t('buttons.certificates-of-participation')}
            </h1>

            <Certification
              completedChallenges={completedChallenges}
              createFlashMessage={createFlashMessage}
              isLogicaDeProgramacaoCert={isLogicaDeProgramacaoCert}
              isHtmlCssJavascriptCert={isHtmlCssJavascriptCert}
              isReactCert={isReactCert}
              isHonest={isHonest}
              username={username}
              verifyCert={verifyCert}
            />

            <Honesty isHonest={isHonest} updateIsHonest={updateIsHonest} />
          </main>
        </Row>
      </Grid>
      <Spacer size='medium' />
    </>
  );
}

ShowCertificateOfParticipation.displayName = 'ShowCertificateOfParticipation';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowCertificateOfParticipation);
