import React from 'react';

function Article(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <mask
        id='mask0_5939_309'
        width='24'
        height='24'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
        style={{ maskType: 'alpha' }}
      >
        <path fill='#D9D9D9' d='M0 0h24v24H0z'></path>
      </mask>
      <g mask='url(#mask0_5939_309)'>
        <path
          fill='#484848'
          d='M7.25 16.75h6.5v-1.5h-6.5zm0-4h9.5v-1.5h-9.5zm0-4h9.5v-1.5h-9.5zM5.308 20.5q-.758 0-1.283-.525a1.75 1.75 0 0 1-.525-1.283V5.308q0-.758.525-1.283T5.308 3.5h13.384q.758 0 1.283.525t.525 1.283v13.384q0 .758-.525 1.283t-1.283.525zm0-1.5h13.384a.3.3 0 0 0 .212-.096.3.3 0 0 0 .096-.212V5.308a.3.3 0 0 0-.096-.212.3.3 0 0 0-.212-.096H5.308a.3.3 0 0 0-.212.096.3.3 0 0 0-.096.212v13.384q0 .116.096.212a.3.3 0 0 0 .212.096'
        ></path>
      </g>
    </svg>
  );
}

Article.displayName = 'Article';

export default Article;
