// Package Utilities
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { createSelector } from 'reselect';

// Local Utilities
import { executeGA } from '../../../redux/actions';
import { closeModal, resetChallenge } from '../redux/actions';
import { isResetModalOpenSelector } from '../redux/selectors';

import { Modal } from '../../../components/ui/Modal';
import Button from '../../../components/ui/Button';
import { ButtonTypes } from '../../../components/ui/Button/button-types';

// Styles
import './reset-modal.css';

// Types
interface ResetModalProps {
  readonly close: () => void;
  readonly isOpen: boolean;
  readonly reset: () => void;
}

// Redux Setup
const mapStateToProps = createSelector(
  isResetModalOpenSelector,
  (isOpen: boolean) => ({
    isOpen
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      close: () => closeModal('reset'),
      executeGA,
      reset: () => resetChallenge()
    },
    dispatch
  );

function withActions(...fns: Array<() => void>) {
  return () => fns.forEach(fn => fn());
}

// Component
function ResetModal({ reset, close, isOpen }: ResetModalProps): JSX.Element {
  const { t } = useTranslation();
  if (isOpen) {
    executeGA({ event: 'pageview', pagePath: '/reset-modal' });
  }

  return (
    <Modal.Root
      animation={false}
      keyboard={true}
      onHide={close}
      show={isOpen}
      className='reset-modal-container'
    >
      <Modal.Header onHide={close}>{t('learn.reset')}</Modal.Header>
      <Modal.Body>
        <div>
          <p>{t('learn.reset-warn')}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-cy='reset-modal-confirm'
          onClick={withActions(close)}
          buttonType={ButtonTypes.Tertiary}
          width='calc(50% - 8px)'
        >
          {t('buttons.cancel')}
        </Button>
        <Button
          data-cy='reset-modal-cancel'
          onClick={withActions(reset, close)}
          buttonType={ButtonTypes.Primary}
          width='calc(50% - 8px)'
        >
          {t('buttons.confirm-reset')}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

ResetModal.displayName = 'ResetModal';

export default connect(mapStateToProps, mapDispatchToProps)(ResetModal);
