import React from 'react';

type Props = {
  iconcolor?: string;
};

function ArrowCircleRight(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement> & Props
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='56'
      height='56'
      fill='none'
      viewBox='0 0 56 56'
      {...props}
    >
      <mask
        id='mask0_2802_2168'
        style={{ maskType: 'alpha' }}
        width='56'
        height='56'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill={props.fill ?? '#D9D9D9'} d='M0 0H56V56H0z'></path>
      </mask>
      <g mask='url(#mask0_2802_2168)'>
        <path
          fill={props.fill ?? '#fff'}
          d='M28 35.305L35.305 28 28 20.695l-1.238 1.238 5.169 5.17H20.102v1.794h11.829l-5.17 5.17L28 35.305zM28.008 49c-2.895 0-5.613-.551-8.155-1.653-2.541-1.102-4.767-2.608-6.678-4.516a21.374 21.374 0 01-4.52-6.672C7.552 33.619 7 30.903 7 28.008c0-2.904.551-5.634 1.653-8.19 1.102-2.557 2.608-4.781 4.516-6.672 1.91-1.891 4.133-3.388 6.672-4.491C22.381 7.552 25.097 7 27.992 7c2.904 0 5.634.551 8.19 1.653 2.557 1.102 4.781 2.598 6.672 4.487 1.891 1.89 3.388 4.111 4.491 6.665C48.448 22.36 49 25.088 49 27.992c0 2.895-.551 5.613-1.653 8.155-1.102 2.541-2.598 4.767-4.487 6.678-1.89 1.91-4.111 3.417-6.665 4.52C33.64 48.448 30.912 49 28.008 49zM28 47.205c5.343 0 9.88-1.867 13.61-5.602 3.73-3.735 5.595-8.27 5.595-13.603 0-5.343-1.865-9.88-5.595-13.61-3.73-3.73-8.267-5.595-13.61-5.595-5.334 0-9.868 1.865-13.603 5.595-3.735 3.73-5.602 8.267-5.602 13.61 0 5.334 1.867 9.868 5.602 13.603 3.735 3.735 8.27 5.602 13.603 5.602z'
        ></path>
      </g>
    </svg>
  );
}

ArrowCircleRight.displayName = 'ArrowCircleRight';

export default ArrowCircleRight;
