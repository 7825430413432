import Button from '@ui/Button';
import { ButtonTypes } from '@ui/Button/button-types';
import { Modal } from '@ui/Modal';
import React from 'react';
import { discordLocation } from '../../../../../../../config/env.json';

import './modal-redirect-discord.css';

type ModalRedirectDiscordProps = {
  readonly isOpen: boolean;
  readonly close: () => void;
};

function ModalRedirectDiscord({ isOpen, close }: ModalRedirectDiscordProps) {
  return (
    <Modal.Root show={isOpen} onHide={close} className='modal-redirect-discord'>
      <Modal.Header onHide={close}>AVISO DE REDIRECIONAMENTO</Modal.Header>
      <Modal.Body>
        <p>
          Você será direcionado para a nossa comunidade no discord, onde pode
          tirar dúvidas sobre atividades do curso com alunos e monitores.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          buttonType={ButtonTypes.Tertiary}
          width='calc(50% - 8px)'
          onClick={close}
        >
          SAIR
        </Button>
        <Button
          buttonType={ButtonTypes.Primary}
          width='calc(50% - 8px)'
          href={discordLocation}
        >
          CONTINUAR
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

export default ModalRedirectDiscord;
