import React from 'react';
import Article from '@assets/icons/article';

import './challenge-title.css';

type StyleTypeProps = 'type-1' | 'type-2';

interface ChallengeTitleProps {
  readonly children: string;
  readonly type?: StyleTypeProps;
}

const removeIconsFromTitle = (title: string): string => {
  return title
    .replace('▶️ ', '')
    .replace('📄 ', '')
    .replace('📘 ', '')
    .replace('️️🎓 ', '');
};

function ChallengeTitle({
  children,
  type = 'type-1'
}: ChallengeTitleProps): JSX.Element {
  return (
    <div className={`challenge-title ${type}`}>
      {type === 'type-2' && <Article />}
      <h4 id='title-text'>{removeIconsFromTitle(children)}</h4>
    </div>
  );
}

ChallengeTitle.displayName = 'ChallengeTitle';

export default ChallengeTitle;
