import React from 'react';
import { navigate } from 'gatsby-link';
import { Dispatch, bindActionCreators } from 'redux';
import { createSelector } from 'reselect';
import { ChallengeMeta } from '@redux/prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { removeFlashMessage } from '@components/Flash/redux';
import { challengeMetaSelector } from '../../../templates/Challenges/redux/selectors';
import { nextChallenge } from '../../../templates/Challenges/redux/actions';
import { ButtonTypes } from '../../ui/Button/button-types';
import Button from '../../ui/Button';

import './content-challenge-layout.css';
import '../challenge-toast.css';

type ContentChallengeLayoutProps = {
  readonly children: React.ReactNode;
  readonly disabledContinueButton: boolean;
  readonly challengeMeta: ChallengeMeta;
  readonly nextChallenge: () => void;
  readonly removeFlashMessage: typeof removeFlashMessage;
};

const mapStateToProps = createSelector(
  challengeMetaSelector,
  (challengeMeta: ChallengeMeta) => ({
    challengeMeta
  })
);

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      nextChallenge,
      removeFlashMessage
    },
    dispatch
  );

function ContentChallengeLayout({
  children,
  disabledContinueButton,
  challengeMeta,
  nextChallenge,
  removeFlashMessage
}: ContentChallengeLayoutProps): JSX.Element {
  const handleExitClick = () => {
    const { superBlock } = challengeMeta;
    void navigate(`/learn/${superBlock}`);
    removeFlashMessage();
  };

  const handleContinueClick = () => {
    nextChallenge();
    removeFlashMessage();
  };

  return (
    <div className='content-challenge-layout-container'>
      {children}

      <div className='footer'>
        <div className='footer-button-options'>
          <Button
            buttonType={ButtonTypes.Tertiary}
            width='133px'
            onClick={handleExitClick}
          >
            SAIR
          </Button>
          <Button
            buttonType={ButtonTypes.Primary}
            width='133px'
            onClick={handleContinueClick}
            disabled={disabledContinueButton}
          >
            CONTINUAR
          </Button>
        </div>
      </div>
    </div>
  );
}

ContentChallengeLayout.displayName = 'ContentChallengeLayout';

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ContentChallengeLayout));
