import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ButtonTypes } from '../ui/Button/button-types';
import Button from '../ui/Button';

import './honesty.css';

type HonestyProps = {
  isHonest: boolean;
  updateIsHonest: (obj: { isHonest: boolean }) => void;
};

const email = 'devstart@sc.senai.br';
const Honesty = ({ isHonest, updateIsHonest }: HonestyProps): JSX.Element => {
  const { t } = useTranslation();
  const buttonText = isHonest
    ? t('buttons.accepted-honesty')
    : t('buttons.agree');

  return (
    <section id='honesty-policy' className='section-honesty-policy'>
      <h1 className='title-section'>{t('settings.headings.honesty')}</h1>

      <div className='honesty-panel'>
        <p>{t('settings.honesty.p1')}</p>
        <p>{t('settings.honesty.p2')}</p>
        <p>{t('settings.honesty.p3')}</p>
        <p>{t('settings.honesty.p4')}</p>
        <p>{t('settings.honesty.p5')}</p>
        <p>
          <Trans i18nKey='settings.honesty.p6'>
            <a href={`mailto:${email}`}>{{ email }}</a>
          </Trans>
        </p>
      </div>

      <Button
        buttonType={ButtonTypes.Primary}
        disabled={isHonest}
        aria-disabled={isHonest}
        width='100%'
        onClick={() => !isHonest && updateIsHonest({ isHonest: true })}
      >
        {buttonText}
      </Button>
    </section>
  );
};

Honesty.displayName = 'Honesty';

export default Honesty;
