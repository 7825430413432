import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Image from '@assets/icons/image';
import { mainPreviewId, scrollManager } from '../utils/frame';

import './preview.css';

export interface PreviewProps {
  readonly disableIframe?: boolean;
  readonly previewMounted: () => void;
  readonly previewId?: string;
}

function Preview({
  disableIframe,
  previewMounted,
  previewId
}: PreviewProps): JSX.Element {
  const { t } = useTranslation();
  const [iframeStatus, setIframeStatus] = useState<boolean | undefined>(false);
  const iframeToggle = iframeStatus ? 'disable' : 'enable';

  useEffect(() => {
    previewMounted();
  }, [previewMounted]);

  useEffect(() => {
    setIframeStatus(disableIframe);
  }, [disableIframe]);

  useEffect(() => {
    return () => {
      scrollManager.setPreviewScrollPosition(0);
    };
  }, []);

  const id = previewId ?? mainPreviewId;

  return (
    <div className={`notranslate challenge-preview ${iframeToggle}-iframe`}>
      <div className='panel-header'>
        <Image />
        <h4>Preview</h4>
      </div>

      <div className='challenge-preview-frame-container'>
        <iframe
          className={'challenge-preview-frame'}
          id={id}
          title={t('learn.chal-preview') ?? ''}
        />
      </div>
    </div>
  );
}

Preview.displayName = 'Preview';

export default Preview;
