import React, { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonTypes } from '../ui/Button/button-types';
import Button from '../ui/Button';
import { Modal } from '../ui/Modal';
import Close2 from '../../assets/icons/close2';

import './delete-modal.css';

type DeleteModalProps = {
  readonly show: boolean;
  readonly deleteAccount: () => void;
  readonly onHide: () => void;
};

function DeleteModal({
  show,
  onHide,
  deleteAccount
}: DeleteModalProps): JSX.Element {
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false);

  const { t } = useTranslation();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxSelected(event.currentTarget.checked);
  };

  return (
    <Modal.Root
      keyboard={true}
      onHide={onHide}
      show={show}
      className='delete-account-modal'
    >
      <Modal.Header
        closeButton={true}
        closeIcon={<Close2 width={24} height={24} onClick={onHide} />}
      >
        {t('settings.danger.delete-title')}
      </Modal.Header>

      <Modal.Body>
        <div className='delete-account-modal-content'>
          <p>{t('settings.danger.delete-p1')}</p>

          <div className='delete-account-modal-confirm'>
            <div className='checkbox'>
              <input
                type='checkbox'
                id='chk1'
                className='checkbox'
                name='chk'
                onChange={handleCheckboxChange}
              />
              <label htmlFor='chk1'>
                Confirmo que desejo deletar minha conta e estou ciente da perda
                irreversível de todos os meus dados.
              </label>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={onHide}
          type='button'
          buttonType={ButtonTypes.Light}
          style={{ width: 218, height: 44 }}
        >
          {t('settings.danger.nevermind')}
        </Button>

        <Button
          onClick={deleteAccount}
          type='button'
          buttonType={ButtonTypes.Quaternary}
          disabled={!isCheckboxSelected}
          style={{ width: 218, height: 44 }}
        >
          {t('settings.danger.delete-confirm')}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

DeleteModal.displayName = 'DeleteModal';

export default DeleteModal;
