/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { Component } from 'react';

import type { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import type { CamperProps } from '../profile/components/camper';
import { createFlashMessage, removeFlashMessage } from '../Flash/redux';
import { FlashMessages } from '../Flash/redux/flash-messages';
import Button from '../ui/Button';
import { ButtonTypes } from '../ui/Button/button-types';
import Input from '../ui/Input';
import { type ThemeProps } from './theme';

import './about.css';

type AboutProps = ThemeProps &
  Omit<
    CamperProps,
    'linkedin' | 'joinDate' | 'githubProfile' | 'twitter' | 'website'
  > & {
    sound: boolean;
    keyboardShortcuts: boolean;
    submitNewAbout: (formValues: FormValues) => void;
    t: TFunction;
    toggleSoundMode: (sound: boolean) => void;
    toggleKeyboardShortcuts: (keyboardShortcuts: boolean) => void;
    createFlashMessage: typeof createFlashMessage;
    removeFlashMessage: typeof removeFlashMessage;
  };

type FormValues = Pick<
  AboutProps,
  'name' | 'location' | 'picture' | 'about' | 'cpf' | 'phone'
>;

type AboutState = {
  formValues: FormValues;
  originalValues: FormValues;
  formClicked: boolean;
  isPictureUrlValid: boolean;
  inputPhoneHandler: string;
  inputCPFHandler: string;
  inputNameHandler: string;
};

class AboutSettings extends Component<AboutProps, AboutState> {
  validationImage: HTMLImageElement;
  public static readonly displayName: string = 'AboutSettings';
  constructor(props: AboutProps) {
    super(props);
    this.validationImage = new Image();
    const {
      name = '',
      location = '',
      picture = '',
      about = '',
      cpf = '',
      phone = ''
    } = props;
    const values = {
      name,
      cpf,
      phone,
      location,
      picture,
      about
    };
    this.state = {
      formValues: { ...values },
      originalValues: { ...values },
      formClicked: false,
      isPictureUrlValid: true,
      inputNameHandler: 'form-control',
      inputCPFHandler: 'form-control',
      inputPhoneHandler: 'form-control'
    };
  }

  componentDidUpdate() {
    const { name, location, picture, about, cpf, phone } = this.props;
    const { formValues, formClicked } = this.state;
    if (
      formClicked &&
      name === formValues.name &&
      cpf === formValues.cpf &&
      phone === formValues.phone &&
      location === formValues.location &&
      picture === formValues.picture &&
      about === formValues.about
    ) {
      return this.setState({
        originalValues: {
          name,
          cpf,
          phone,
          location,
          picture,
          about
        },
        formClicked: false
      });
    }
    return null;
  }

  isFormPristine = () => {
    const { formValues, originalValues } = this.state;
    return (
      this.state.isPictureUrlValid === false ||
      (Object.keys(originalValues) as Array<keyof FormValues>)
        .map(key => originalValues[key] === formValues[key])
        .every(bool => bool)
    );
  };

  isCPFValid = (cpf: string) => {
    const cpfRaw = cpf.replace(/[^\d]/g, '');

    if (/^(\d)\1+$/.test(cpfRaw)) {
      return false;
    }

    const calculaDigito = (cpf: string, peso: number) => {
      const soma = cpf
        .slice(0, peso)
        .split('')
        .map(Number)
        .reduce((acc, digit, index) => acc + digit * (peso - index), 0);
      const resto = soma % 11;
      return resto < 2 ? 0 : 11 - resto;
    };

    const primeiroDigito = calculaDigito(cpfRaw.slice(0, 9), 10);
    const segundoDigito = calculaDigito(cpfRaw.slice(0, 10), 11);

    if (
      parseInt(cpfRaw.charAt(9)) !== primeiroDigito ||
      parseInt(cpfRaw.charAt(10)) !== segundoDigito
    ) {
      return false;
    } else {
      return true;
    }
  };

  handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const { formValues } = this.state;

    const isEmpty = this.isEmptyField(formValues);

    this.props.removeFlashMessage();

    if (isEmpty.result) {
      this.setErrorClassInput(isEmpty.field);
      this.props.createFlashMessage({
        message: FlashMessages.InvalidFormField,
        type: 'danger'
      });
    } else if (this.isCPFValid(this.state.formValues.cpf)) {
      const { submitNewAbout } = this.props;
      if (this.state.isPictureUrlValid === true && !this.isFormPristine()) {
        return this.setState({ formClicked: true }, () =>
          submitNewAbout(this.state.formValues)
        );
      } else {
        return false;
      }
    } else {
      this.props.createFlashMessage({
        message: FlashMessages.InvalidCPF,
        type: 'danger'
      });
    }
  };

  handleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.slice(0);
    return this.setState(state => ({
      inputNameHandler: 'form-control',
      formValues: {
        ...state.formValues,
        name: value
      }
    }));
  };

  handleCpfChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.slice(0);
    return this.setState(state => ({
      inputCPFHandler: 'form-control',
      formValues: {
        ...state.formValues,
        cpf: value
      }
    }));
  };

  handlePhoneChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.slice(0);
    return this.setState(state => ({
      inputPhoneHandler: 'form-control',
      formValues: {
        ...state.formValues,
        phone: value
      }
    }));
  };

  handleLocationChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = (e.target as HTMLInputElement).value.slice(0);
    return this.setState(state => ({
      formValues: {
        ...state.formValues,
        location: value
      }
    }));
  };

  isEmptyField = (obj: FormValues) => {
    if (obj.name === '') {
      return { result: true, field: 'name' };
    } else if (this.formatPhoneValue(obj.phone)) {
      return { result: true, field: 'phone' };
    } else if (this.formatCpfValue(obj.cpf)) {
      return { result: true, field: 'cpf' };
    } else {
      return { result: false, field: 'none' };
    }
  };

  formatPhoneValue = (value: string) => {
    const phoneWithoutMask: string = value
      .replace(/ /g, '')
      .replace(/\(/g, '')
      .replace(/\)/g, '')
      .replace(/-/g, '')
      .replace(/_/g, '');
    return phoneWithoutMask.length < 11;
  };

  formatCpfValue = (value: string) => {
    const cpfWithoutMask: string = value
      .replace(/\./g, '')
      .replace(/-/g, '')
      .replace(/_/g, '');
    return cpfWithoutMask.length < 10;
  };

  setErrorClassInput = (input: string) => {
    if (input === 'name') {
      this.setState({ inputNameHandler: 'form-control form-control-error' });
    }
    if (input === 'phone') {
      this.setState({ inputPhoneHandler: 'form-control form-control-error' });
    }
    if (input === 'cpf') {
      this.setState({ inputCPFHandler: 'form-control form-control-error' });
    }
  };

  componentDidMount() {
    this.validationImage.addEventListener('error', this.errorEvent);
    this.validationImage.addEventListener('load', this.loadEvent);
  }

  componentWillUnmount() {
    this.validationImage.removeEventListener('load', this.loadEvent);
    this.validationImage.removeEventListener('error', this.errorEvent);
  }

  loadEvent = () => this.setState({ isPictureUrlValid: true });
  errorEvent = () =>
    this.setState(state => ({
      isPictureUrlValid: state.formValues.picture === ''
    }));

  render() {
    const {
      formValues: { name, cpf, phone, location }
    } = this.state;
    const { t } = this.props;
    const ariaLabel = t('settings.headings.personal-info');
    return (
      <div className='about-settings'>
        <div>
          <form id='camper-identity' onSubmit={this.handleSubmit}>
            <h4 className='section-header-text' id='personal-data'>
              Dados pessoais
            </h4>

            <div className='group-form' aria-label={ariaLabel}>
              <Input
                controlId='about-name'
                label={t('settings.labels.name') ?? undefined}
                onChange={this.handleNameChange}
                value={name}
              />

              <Input
                controlId='about-phone'
                label={t('settings.labels.phone') ?? undefined}
                onChange={this.handlePhoneChange}
                value={phone}
                hasMask
                maskValue='(99) 99999-9999'
              />

              <Input
                controlId='about-cpf'
                label={t('settings.labels.codePerson') ?? undefined}
                onChange={this.handleCpfChange}
                value={cpf}
                hasMask
                maskValue='999.999.999-99'
              />

              <Input
                controlId='about-location'
                label={t('settings.labels.location') ?? undefined}
                onChange={this.handleLocationChange}
                value={location}
              />
            </div>

            <Button
              buttonType={ButtonTypes.Primary}
              disabled={this.isFormPristine()}
              aria-disabled={this.isFormPristine()}
              type='submit'
              style={{ margin: '0 auto' }}
              {...(this.isFormPristine() && { tabIndex: -1 })}
              isLoading={this.state.formClicked}
            >
              {t('buttons.save')}
              <span className='sr-only'>
                {t('settings.headings.personal-info')}
              </span>
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AboutSettings);
