import React from 'react';

function Close3(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      {...props}
    >
      <mask
        id='mask0_4131_11916'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='24'
        height='24'
      >
        <rect width='24' height='24' fill={props.fill ?? '#D9D9D9'} />
      </mask>
      <g mask='url(#mask0_4131_11916)'>
        <path
          d='M6.39953 18.6534L5.3457 17.5995L10.9457 11.9995L5.3457 6.39953L6.39953 5.3457L11.9995 10.9457L17.5995 5.3457L18.6534 6.39953L13.0534 11.9995L18.6534 17.5995L17.5995 18.6534L11.9995 13.0534L6.39953 18.6534Z'
          fill={props.fill ?? '#484848'}
        />
      </g>
    </svg>
  );
}

Close3.displayName = 'Close3';

export default Close3;
