import i18next from 'i18next';
import React from 'react';
import { Link } from '../../../components/helpers/index';
import Home from '../../../assets/icons/home';

import './bread-crumb.css';

interface BreadCrumbItemsProps {
  key: string;
  value: string;
  redirectsTo: string;
}

interface BreadCrumbProps {
  readonly breadCrumbItems: BreadCrumbItemsProps[];
}

function BreadCrumb({ breadCrumbItems }: BreadCrumbProps): JSX.Element {
  return (
    <nav className='container-bread-crumb'>
      <div className='module-theme-path'>
        <Link className='path-bread-crumb' to='/learn'>
          <Home />
          {i18next.t(`buttons.start`)}
        </Link>

        <p className='path-bread-crumb-next'>&nbsp;{' > '}&nbsp;</p>

        {breadCrumbItems.map((item, index, array) => (
          <>
            <Link className='path-bread-crumb' to={item.redirectsTo}>
              {item.value}
            </Link>

            {index < array.length - 1 && (
              <p className='path-bread-crumb-next'>&nbsp;{' > '}&nbsp;</p>
            )}
          </>
        ))}
      </div>
    </nav>
  );
}

BreadCrumb.displayName = 'BreadCrumb';

export default BreadCrumb;
