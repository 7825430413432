/*
 * This regex is not for validation, it is purely to see
 * if we are looking at something like what we want to validate
 * before we try to validate.
 */
export const maybeEmailRE = /.*@.*\.\w\w/;
export const maybeUrlRE = /https?:\/\/.*\..*/;
export const hasProtocolRE = /^http/;

export function formatPhoneNumber(number: string): string | null {
  const cleaned = number.toString().replace(/\D/g, '');
  const match = cleaned.match(/^55(\d{2})(\d{4})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return null;
}
